import React from "react";
import ConnectWalletCard from "./ConnectWalletCard";
import ConnectWalletData from "./ConnectWalletData";

const ConnectWalletView = () => {
  return (
    <div id="connect-wallet-view">
      <ConnectWalletCard />
      <ConnectWalletData />
    </div>
  );
};

export default ConnectWalletView;
