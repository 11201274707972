import { useState, useEffect } from "react";
import { useKeeper } from "../../providers/keeper/keeperProvider";

const usePublicAddress = () => {
  const [publicAddress, setPublicAddress] = useState<string>();
  const { publicState } = useKeeper();

  useEffect(() => {
    if (!publicState?.account) {
      setPublicAddress(undefined);
      return;
    } else if (publicAddress !== publicState.account.address) {
      setPublicAddress(publicState.account.address);
    }
  }, [publicAddress, publicState?.account]);

  return publicAddress;
};

export default usePublicAddress;
