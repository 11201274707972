import React from "react";
import useNftBlockchainActions from "./keeper/useNftBlockchainActions";

export type Status = "ready" | "working" | "success" | "failed";

const useSendNFT = (assetId: string) => {
  const [status, setStatus] = React.useState<Status>("ready");
  const [error, setError] = React.useState<any>();
  const { sendNFT } = useNftBlockchainActions();

  const perform = async (recipient: string) => {
    setStatus("working");

    return await sendNFT(assetId, recipient).then(
      ({ transferred, error: transferError }) => {
        if (transferred) {
          setStatus("success");
        } else {
          setStatus("failed");
          setError(transferError);
        }
      }
    );
  };

  const reset = () => {
    setError(undefined);
    setStatus("ready");
  };

  return {
    status,
    error,
    perform,
    reset,
  };
};

export default useSendNFT;
