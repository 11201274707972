import React from "react";
import useNftBlockchainActions from "./useNftBlockchainActions";

type TransactionStatus =
  | "waitingForConfirm"
  | "waitingForKeeperConfirm"
  | "success"
  | "error";

interface UseBurnNFTTransactionProps {
  status: TransactionStatus;
  perform: (assetId: string) => Promise<any>;
  reset: () => void;
  error?: string;
}

const useBurnNFTTransaction: () => UseBurnNFTTransactionProps = () => {
  const [error, setError] = React.useState<string>();
  const [status, setStatus] =
    React.useState<TransactionStatus>("waitingForConfirm");

  const { burnNFT } = useNftBlockchainActions();

  const reset = () => {
    setStatus("waitingForConfirm");
    setError(undefined);
  };

  const perform = async (assetId: string) => {
    setStatus("waitingForKeeperConfirm");

    await burnNFT(assetId)
      .then(({ burned, error }) => {
        if (burned) {
          setStatus("success");
        } else {
          setStatus("error");
          setError(error.message);
        }
      })
      .catch((e) => {
        setStatus("error");
        setError(e.message);
      });
  };

  return { status, reset, perform, error };
};

export default useBurnNFTTransaction;
