import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { IRoute } from "./routes";
import { isMobile } from "react-device-detect";
import DesktopLayout from "../components/Layout/DesktopLayout";

export interface RouterProps {
  routes: readonly IRoute[];
  Layout: React.FC;
}

const Router: React.FC<RouterProps> = ({ routes }) => {
  return (
    <BrowserRouter>
      <Routes>
        {routes
          .filter((route: IRoute) => {
            if (isMobile) {
              return route.availableOnMobile === isMobile;
            } else {
              return true;
            }
          })
          .map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                path !== "/*" ? (
                  <DesktopLayout>
                    <Component />
                  </DesktopLayout>
                ) : (
                  <Component />
                )
              }
            />
          ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
