import React from "react";
import { useBlockchainNetworks } from "../providers/blockchainNetworks/blockchainNetworksProvider";
import { useKeeper } from "../providers/keeper/keeperProvider";
import Button from "./Button";
import Progress from "./Progress";
import ProgressText from "./ProgressText";
import Text from "./Text";

const ConnectWalletData = () => {
  const keeper = useKeeper();
  const { validNetwork } = useBlockchainNetworks();

  const keeperUrl =
    "https://chrome.google.com/webstore/detail/skey-keeper/dcigkldapeipokagfmcjaijmngcagkcp";

  const isKeeperConnected = React.useMemo(() => {
    return Boolean(keeper.publicState?.network);
  }, [keeper.publicState?.network]);

  const isValidNetwork = React.useMemo(() => {
    return isKeeperConnected && validNetwork;
  }, [validNetwork, isKeeperConnected]);

  return (
    <div id="connect-wallet-data">
      <Progress step={1} width={"100%"} />
      <ProgressText step={1} />

      {keeper.status === "unavailable" && (
        <>
          <Text
            style={{
              color: "var(--link)",
              cursor: "pointer",
              marginBottom: 10,
            }}
            onClick={() => window.open(keeperUrl, "_blank")?.focus()}
          >
            Download Skey Keeper for Chrome
          </Text>
          <Text>
            You need to download Skey Keeper extension and set up your first
            account. Refresh the page afterwards.
          </Text>
        </>
      )}
      {keeper.status === "noAccount" && (
        <Text>
          To start creating NFTs, you need a blockchain account. Please create
          an account in Skey Keeper.
        </Text>
      )}
      {isKeeperConnected && !isValidNetwork && (
        <Text>Incorrect network detected. Please switch to testnet.</Text>
      )}
      <Button
        disabled={
          keeper.status === "unavailable" || keeper.status === "noAccount"
        }
        onClick={keeper.connect}
        shadow
        showArrowRight
        color="primary"
      >
        {isKeeperConnected
          ? isValidNetwork
            ? "Connect Wallet"
            : `Switch network to testnet`
          : "Connect Wallet"}
      </Button>
    </div>
  );
};

export default ConnectWalletData;
