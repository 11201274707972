import React from "react";
import Card from "./Card";

interface ButtonCardProps {
  imageAbsolutePath: string;
  topText: string;
  bottomText: string;
  onClickEffect: Function;
}

const ButtonCard = (props: ButtonCardProps) => {
  return (
    <Card className="create">
      <button className="button-card" onClick={() => props.onClickEffect()}>
        <img src={props.imageAbsolutePath} alt="icon" className="icon" />
        <p className="top-text">{props.topText}</p>
        <p className="bottom-text">{props.bottomText}</p>
      </button>
    </Card>
  );
};

export default ButtonCard;
