import React from "react";
import { inspect } from "util";
import { IPFSMetadata } from "../IPFS/IPFSTypes";
import { useIPFS } from "../IPFS/useIPFS";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import useNftBlockchainActions from "./keeper/useNftBlockchainActions";

export interface UseNFTState {
  asset: BlockchainAssetDetails | null;
  metadata: IPFSMetadata | null;
  error: string | null;
  loading: boolean;
  find: () => void;
}

export const useNFT = (id: string): UseNFTState => {
  const blockchainActions = useNftBlockchainActions();
  const ipfs = useIPFS();

  const [asset, setAsset] = React.useState<BlockchainAssetDetails | null>(null);
  const [metadata, setMetadata] = React.useState<IPFSMetadata | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const find = React.useCallback(async () => {
    if (id.length < 32) {
      setError("invalid id length");
      setLoading(false);
      setAsset(null);
      setMetadata(null);
    }

    try {
      setAsset(null);
      setMetadata(null);
      setError(null);
      setLoading(true);

      const [asset, whitelisted] = await Promise.all([
        blockchainActions.fetchNft(id),
        blockchainActions.nftExists(id),
      ]);

      if (!whitelisted) throw new Error("nft not whitelisted");

      setAsset(asset);

      if (!asset.description) throw new Error("cid missing");

      const metadata = await ipfs.getMetadata(asset.description);
      setMetadata(metadata);
    } catch (e) {
      console.error(e);
      setError(inspect(e));
    } finally {
      setLoading(false);
    }
  }, [id, blockchainActions, ipfs]);

  return { asset, metadata, error, loading, find };
};
