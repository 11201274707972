import {
  IsString,
  ValidateIf,
  IsPositive,
  IsInt,
  IsMimeType,
  ValidateNested,
  IsArray,
  IsObject,
} from "class-validator";
import { Type } from "class-transformer";

export type UploadStatus = "ready" | "uploading" | "success" | "failed";

export interface IPFSConfig {
  gatewayUrl: string;
  apiUrl: string;
  token: string;
  schemaVersion: number;
}

export interface IPFSCreateMetadata {
  name?: string;
  description?: string;
  url?: string;
  file?: File | null;
  fileCid?: string | null;
  creator?: string;
}

export interface IPFSMetadata {
  name: string;
  description: string;
  attributes: Record<string, any>;
  creator: string;
  mainMediaCid: string | null;
  media: IPFSMedia[];
  timestamp: number;
  schemaVersion: number;
}

interface IPFSMetadataFetchSuccess {
  error: false;
  metadata: IPFSMetadata;
}

interface IPFSMetadataFetchFail {
  error: true;
}

export type IPFSMetadataFetchResult =
  | IPFSMetadataFetchSuccess
  | IPFSMetadataFetchFail;

export interface IPFSMedia {
  cid: string;
  filename: string;
  type: string;
}

export class IPFSMediaSchema implements IPFSMedia {
  @IsString()
  cid: string;

  @IsString()
  filename: string;

  @IsMimeType()
  type: string;
}

export class IPFSMetadataSchema implements IPFSMetadata {
  @IsString()
  name: string;

  @IsString()
  description: string;

  @IsObject()
  attributes: Object;

  @IsString()
  creator: string;

  @IsString()
  @ValidateIf((_, value) => value !== null)
  mainMediaCid: string | null;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => IPFSMediaSchema)
  media: IPFSMedia[];

  @IsPositive()
  @IsInt()
  timestamp: number;

  @IsPositive()
  @IsInt()
  schemaVersion: number;
}
