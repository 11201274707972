import React from "react";
import ButtonCard from "../components/ButtonCard";
import SearchBar from "../components/SearchBar";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  return (
    <>
      <div id="home">
        <SearchBar
          value={search}
          setValue={setSearch}
          onSearch={() => navigate(`/nft/${search}`)}
          style={{ marginTop: 30 }}
        />
        <div className="new-nft-button">
          <ButtonCard
            imageAbsolutePath={"/images/svg/plus-img.svg"}
            topText={"Create new NFT"}
            bottomText={""}
            onClickEffect={() => navigate("/create")}
          />
        </div>
      </div>
      {isMobile && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img
            src="images/svg/chrome.svg"
            alt="chrome"
            style={{ marginBottom: "20px" }}
          />
          <p
            style={{
              lineHeight: "21px",
              fontSize: "14px",
              letterSpacing: "0.02em",
              fontWeight: "400",
            }}
          >
            Use the Chrome browser on your computer to create your own NFT!
          </p>
        </div>
      )}
    </>
  );
};

export default Home;
