import React from "react";

interface HSpaceProps {
  space: string;
}

const HorizontalSpace = ({ space }: HSpaceProps) => {
  return <div style={{ width: space }}></div>;
};

export default HorizontalSpace;
