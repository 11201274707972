import React from "react";

interface LetterCounterProps {
  current: number;
  max: number;
}

const LetterCounter = ({ current, max }: LetterCounterProps) => {
  return (
    <span className="letter-counter">
      {current}/{max}
    </span>
  );
};

export default LetterCounter;
