import React from "react";
import { useKeeper } from "../providers/keeper/keeperProvider";
import * as IdentityImg from "identity-img";
import styled from "styled-components";
import { randomBytes, base58Encode } from "@waves/ts-lib-crypto";
import usePublicAddress from "../hooks/keeper/usePublicAddress";

export const shortAddress = (address?: string, size = 6) => {
  if (!address) return "No account";
  if (address.length !== 35) return "Invalid address";

  return `${address.substring(0, size)}...${address.substring(
    address.length - size,
    address.length
  )}`;
};

const prettyBalance = (balance?: string) => {
  const num = Number(balance);
  if (!Number.isFinite(num)) return "0.00000000";

  return (num / 10 ** 8).toFixed(8);
};

const Container = styled.div`
  background-color: white;
  width: calc(100% - 40px);
  height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 50px;

  .avatar {
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }

  .connected {
    font-weight: 600;
    font-size: 14px;
    color: var(--success);
    margin-bottom: 30px;
  }

  .address {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark);
    margin-bottom: 6px;
  }

  .balance {
    font-weight: 500;
    font-size: 12px;
    color: var(--dark);
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 15px;
  }

  .card {
    display: flex;
    justify-content: center;
  }
`;

const WalletInfo: React.FC = () => {
  const keeper = useKeeper();
  const address = usePublicAddress();

  const avatarUrl = React.useMemo(() => {
    const rand = base58Encode(randomBytes(35));
    const input = address ?? rand;

    return IdentityImg.create(input, { size: 120, rows: 8, cells: 8 });
  }, [address]);

  return (
    <Container>
      <div
        className="connected"
        style={{
          color: address ? "var(--success)" : "var(--dark)",
        }}
      >
        Connected
      </div>

      <div className="card">
        <div className="info">
          <div className="address">{shortAddress(address)}</div>
          <div className="balance">
            {prettyBalance(keeper.publicState?.account?.balance?.available)}
            {" Skey"}
          </div>
        </div>
        <img
          className="avatar"
          src={avatarUrl ?? ""}
          alt="avatar"
          style={{
            filter: address
              ? undefined
              : "grayscale(1) brightness(1.5) blur(0.5px)",
          }}
        />
      </div>
    </Container>
  );
};

export default WalletInfo;
