import React from "react";
import { useKeeper } from "../providers/keeper/keeperProvider";
import ConnectWallet from "./ConnectWallet";
import WalletInfo from "./WalletInfo";

const WalletSection: React.FC = () => {
  const { status } = useKeeper();

  if (status === "connected" || status === "noAccount") {
    return <WalletInfo />;
  }

  if (
    status === "notConnected" ||
    status === "unavailable" ||
    status === "loaded"
  ) {
    return <ConnectWallet />;
  }

  return null;
};

export default WalletSection;
