import React from "react";

interface TextProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Text: React.FC<TextProps> = (props) => {
  return (
    <p id="text" style={{ ...props.style }} onClick={props.onClick}>
      {props.children}
    </p>
  );
};

export default Text;
