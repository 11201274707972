import React from "react";
import styled from "styled-components";

export interface BackdropProps {
  visible: boolean;
  onClick?: () => void;
}

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(64, 64, 64, 0.75);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
`;

export const Backdrop: React.FC<BackdropProps> = (props) => {
  return props.visible ? (
    <Container onClick={props.onClick}>{props.children}</Container>
  ) : null;
};
