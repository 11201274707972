import React from "react";
import styled from "styled-components";
import { UseFileUploaderState } from "../IPFS/useFileUploader";
import CircularProgress from "@mui/material/CircularProgress";

export interface ImageUploaderProgressProps {
  uploader?: UseFileUploaderState;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: unset;
  backdrop-filter: blur(4px) brightness(0.7);

  .text {
    font-size: 24px;
    color: white;
    font-weight: 400;
    margin-top: 40px;
  }

  .sub {
    color: white;
    margin-top: 15px;
  }
`;

const ImageUploaderProgress: React.FC<ImageUploaderProgressProps> = (props) => {
  const [finished, setFinished] = React.useState(false);

  React.useEffect(() => {
    if (props.uploader?.status === "success") {
      const handle = setTimeout(() => setFinished(true), 1200);

      return () => clearInterval(handle);
    }

    setFinished(false);
  }, [props.uploader?.status]);

  if (!props.uploader || finished) return null;

  if (props.uploader.status === "uploading") {
    return (
      <Container>
        <CircularProgress size={100} thickness={1} style={{ color: "white" }} />
        <div className="text">Please wait, uploading photo...</div>
      </Container>
    );
  }

  if (props.uploader.status === "success") {
    return (
      <Container>
        <img src="/images/svg/success-white.svg" alt="success" />
        <div className="text">Image uploaded</div>
      </Container>
    );
  }

  if (props.uploader.status === "failed") {
    return (
      <Container>
        <img src="/images/svg/error-white.svg" alt="error" />
        <div className="text">Ups... Something went wrong</div>
        <div className="sub">Try again or try different image</div>
      </Container>
    );
  }

  return null;
};

export default ImageUploaderProgress;
