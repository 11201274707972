import "reflect-metadata";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./components/components.css";
import "./views/views.css";
import { initializeAnalytics } from "./utils/analytics";

initializeAnalytics();

ReactDOM.render(<App />, document.getElementById("root"));
