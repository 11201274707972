import React from "react";

export interface ActionButtonProps {
  children: string;
  imgName: string;
  action: Function;
  disabled: boolean;
  style?: React.CSSProperties;
}

const NFTActionButton: React.FC<ActionButtonProps> = ({
  children,
  imgName,
  action,
  disabled,
  style,
}) => {
  return (
    <button
      className="nft-action-button"
      onClick={() => action()}
      style={{ ...style }}
      disabled={disabled}
    >
      <span className="button-text">{children}</span>
      {imgName && (
        <img
          className="button-img"
          src={`/images/svg/${imgName}.svg`}
          alt="action-icon"
        />
      )}
    </button>
  );
};

export default NFTActionButton;
