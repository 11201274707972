import React from "react";
import styled from "styled-components";
import useSendNFT from "../hooks/useSendNFT";
import { IPFSMetadata } from "../IPFS/IPFSTypes";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import Button from "./Button";

import { Popup, PopupProps } from "./Popup";
import SendNFTForm from "./SendNFTForm";
import Spinner from "./Spinner";

export interface BurnNFTPopupProps extends PopupProps {
  onSuccess: () => void;
  asset: BlockchainAssetDetails;
  metadata: IPFSMetadata | null;
}

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 42px;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;

  span {
    text-align: justify;
    line-height: 1.2;
  }
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
`;

const SendNFTPopup: React.FC<BurnNFTPopupProps> = (props) => {
  const { asset, onSuccess, metadata, ...popupProps } = props;
  const { status, perform, error, reset } = useSendNFT(asset.assetId);

  const onClose = () => {
    if (status === "working") return;

    reset();
    if (props.onClose) props.onClose();
  };

  const headerText = `Transfer NFT '${metadata?.name}'`;

  return (
    <Popup {...popupProps} onClose={onClose} header={headerText}>
      {status === "ready" && <SendNFTForm asset={asset} onSubmit={perform} />}
      {status === "working" && (
        <CenteredContainer>
          <Spinner />
          <span style={{ textAlign: "center" }}>
            Confirm the transaction in Keeper
          </span>
        </CenteredContainer>
      )}
      {status === "success" && (
        <CenteredContainer>
          <Icon src="/images/svg/success-green.svg" alt="Confirmed" />
          <h3 style={{ color: "var(--success)" }}>
            NFT Transferred successfully!
          </h3>
          <Button variant="outlined" color="dark" onClick={onSuccess}>
            Close
          </Button>
        </CenteredContainer>
      )}
      {status === "failed" && (
        <CenteredContainer>
          <Icon src="/images/svg/danger-triangle.svg" alt="Danger triangle" />
          <h3 style={{ color: "var(--error)" }}>Failed to transfer:</h3>
          <span style={{ color: "var(--error)" }}>{error?.message}</span>
          <Button variant="outlined" color="dark" onClick={onClose}>
            Close
          </Button>
        </CenteredContainer>
      )}
    </Popup>
  );
};

export default SendNFTPopup;
