import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div id="header">
      <img
        src="/images/svg/header-logo.svg"
        alt="logo"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default Header;
