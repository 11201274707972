import React from "react";
import Show from "../views/Show";
import Create from "../views/Create";
import Home from "../views/Home";
import NotFound from "../views/NotFound";

export interface IRoute {
  path: string;
  Component: React.FC;
  availableOnMobile: boolean;
}

export const routes: readonly IRoute[] = Object.freeze([
  {
    path: "/",
    Component: Home,
    availableOnMobile: true,
  },
  {
    path: "/create",
    Component: Create,
    availableOnMobile: false,
  },
  {
    path: "/nft/:assetId",
    Component: Show,
    availableOnMobile: true,
  },
  {
    path: "/*",
    Component: NotFound,
    availableOnMobile: true,
  },
]);
