import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { CreateNFTPopup } from "../components/CreateNFTPopup";
import Input from "../components/Input";
import NFTCard from "../components/NFTCard";
import Progress from "../components/Progress";
import usePublicAddress from "../hooks/keeper/usePublicAddress";
import { useFileUploader } from "../IPFS/useFileUploader";
import ConnectWalletView from "../components/ConnectWalletView";
import ProgressText from "../components/ProgressText";
import { useBlockchainNetworks } from "../providers/blockchainNetworks/blockchainNetworksProvider";
import ConfirmNoImagePopup from "../components/ConfirmNoImagePopup";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;

  @media (max-width: 1600px) {
    flex-direction: column;
    width: max-content;
    margin-top: 20px;
    background-color: white;
  }

  @media (max-width: 650px) {
    width: 100%;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 35px;

    @media (max-width: 1600px) {
      max-width: 500px;
      margin-left: 0px;
      padding: 30px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .header {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .progress {
    width: 100%;
  }
`;

const Create: React.FC = () => {
  const { validNetwork } = useBlockchainNetworks();
  const address = usePublicAddress();
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [urlError, setUrlError] = React.useState("");
  const [dropzoneError, setDropzoneError] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [showConfirmImage, setShowConfirmImage] = React.useState(false);
  const fileUploader = useFileUploader();

  const canCreate = () => {
    return !(
      name === "" ||
      description === "" ||
      !address ||
      nameError !== "" ||
      descriptionError !== "" ||
      urlError
    );
  };

  if (!validNetwork) return <ConnectWalletView />;

  return (
    <Container>
      <CreateNFTPopup
        visible={showPopup}
        onClose={() => setShowPopup(false)}
        metadata={{
          name,
          description,
          url,
          creator: address,
          file: fileUploader.file,
          fileCid: fileUploader.cid,
        }}
      />
      <ConfirmNoImagePopup
        visible={showConfirmImage}
        onClose={() => setShowConfirmImage(false)}
        onContinue={() => {
          setShowConfirmImage(false);
          setShowPopup(true);
        }}
      />
      <NFTCard
        name={name}
        description={description}
        url={url}
        uploader={fileUploader}
        dropzoneError={dropzoneError}
        setDropzoneError={setDropzoneError}
      />
      <div className="form-wrapper">
        <Progress step={2} width="100%" />
        <ProgressText step={2} />
        <div className="form">
          <Input
            value={name}
            setInputValue={setName}
            type="input"
            maxLetters={160}
            error={nameError}
            setError={setNameError}
            label="Name (required)"
            placeholder="NFT Name"
          />
          <Input
            value={description}
            setInputValue={setDescription}
            type="textArea"
            maxLetters={320}
            error={descriptionError}
            setError={setDescriptionError}
            label="Description (required)"
            placeholder="NFT Description"
            style={{ width: "100%" }}
          />
          <Input
            value={url}
            setInputValue={setUrl}
            type="URL"
            maxLetters={160}
            error={urlError}
            setError={setUrlError}
            label="URL (optional)"
            placeholder="NFT URL"
          />
          <Button
            disabled={!canCreate()}
            onClick={() =>
              fileUploader.cid ? setShowPopup(true) : setShowConfirmImage(true)
            }
            shadow
            style={{
              width: 255,
              height: 48,
              padding: 0,
              fontSize: 20,
              marginTop: 20,
            }}
          >
            Create NFT!
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
