import React from "react";
import styled from "styled-components";
import { Backdrop } from "./Backdrop";

export interface PopupProps {
  visible: boolean;
  header?: string;
  onClose: () => void;
}

const Container = styled.div`
  background-color: white;
  width: calc(100% - 100px);
  max-width: 600px;
  height: 400px;
  padding: 18px 30px 41px 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  .top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    height: 35px;
    box-sizing: border-box;
  }

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 78%;
    letter-spacing: 0.02em;
  }

  .content {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    padding-left: 5px;
  }

  .tick {
    font-weight: 100;
    font-size: 45px;
    cursor: pointer;
    transition: transform 0.1s linear;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transform: rotate(45deg);

    :hover {
      transform: rotate(135deg);
    }
  }
`;

export const Popup: React.FC<PopupProps> = (props) => {
  if (!props.visible) return null;

  return (
    <React.Fragment>
      <Container>
        <div className="top-bar">
          <div className="title">{props.header}</div>
          <div className="tick" onClick={props.onClose}>
            +
          </div>
        </div>
        <div className="content">{props.children}</div>
      </Container>
      <Backdrop visible onClick={props.onClose}></Backdrop>
    </React.Fragment>
  );
};
