import React from "react";
import NFTList from "../NFTList";
import NFTListHeader from "../NFTListHeader";
import { INFTListItem } from "../NFTListItem";
import WalletSection from "../WalletSection";
import { useIPFS } from "../../IPFS/useIPFS";
import { useNFTCollection } from "../../providers/collections/NFTCollectionProvider";
import CreateNFTListItem from "../CreateNFTListItem";
import SidebarSeparator from "../SidebarSeparator";

const Sidebar: React.FC = () => {
  const { collection, loading } = useNFTCollection().userNfts;
  const ipfsService = useIPFS();

  const items: Partial<INFTListItem>[] = React.useMemo(() => {
    return Object.entries(collection).map(
      ([id, result]): Partial<INFTListItem> => {
        if (result.error) return {};

        const { metadata } = result;

        const img =
          metadata.mainMediaCid && metadata.media.length
            ? ipfsService.getUrl(metadata.mainMediaCid)
            : undefined;

        return {
          id,
          name: metadata.name,
          description: metadata.description,
          img,
        };
      }
    );
  }, [collection, ipfsService]);

  return (
    <React.Fragment>
      <WalletSection />
      <SidebarSeparator />
      <CreateNFTListItem />
      <NFTListHeader />
      <NFTList items={items} loading={loading} style={{ flex: "1 1 0" }} />
    </React.Fragment>
  );
};

export default Sidebar;
