import React from "react";
import Button from "./Button";

interface SearchBarProps {
  value: string;
  setValue: (value: string) => void;
  onSearch: () => void;
  style?: React.CSSProperties;
}

const validateAssetId = (text: string): string | null => {
  if (!text) return null;

  if (text.length < 32) return "Token address is too short";

  if (text.length > 44) return "Token address is too long";

  if (!/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(text)) {
    return "Token address is not valid";
  }

  return null;
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const error = validateAssetId(props.value);

  const keyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !error) {
      props.onSearch();
    }
  };

  return (
    <div style={{ marginBottom: 40, ...(props.style ?? {}) }}>
      <h4 className="search-title">Find NFT!</h4>
      <div className="search-wrapper">
        <div id="search-bar">
          <img
            src="/images/svg/magnifier.svg"
            alt="magnifier"
            className="magnifier"
          />
          <input
            placeholder="Search NFT by address"
            value={props.value}
            onChange={(e) => props.setValue(e.currentTarget.value)}
            style={{ borderColor: !error ? undefined : "var(--error)" }}
            onKeyDown={keyHandler}
          />
          <label
            style={{
              color: "var(--error)",
              fontSize: 12,
              paddingLeft: 2,
              height: 17,
            }}
          >
            {error}
          </label>
        </div>
        <Button
          color="primary"
          shadow={true}
          disabled={props.value === "" || !!error}
          style={{
            paddingLeft: "98px",
            paddingRight: "98px",
          }}
          onClick={props.onSearch}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
