import React from "react";
import useNftBlockchainActions from "./keeper/useNftBlockchainActions";

export const useTokenOwner = (assetId: string) => {
  const [owner, setOwner] = React.useState<string | null>(null);
  const blockchainActions = useNftBlockchainActions();

  React.useEffect(() => {
    let attempts = 0;

    blockchainActions
      .fetchNFTOwner(assetId)
      .then(setOwner)
      .catch(() => null);

    const handle = setInterval(async () => {
      const owner = await blockchainActions
        .fetchNFTOwner(assetId)
        .catch(() => null);

      attempts++;

      if (owner) {
        clearInterval(handle);
        setOwner(owner);
        return;
      }

      if (attempts >= 10) {
        clearInterval(handle);
      }
    }, 1500);

    return () => clearInterval(handle);
  }, [assetId, blockchainActions]);

  return owner;
};
