import React from "react";
import usePublicAddress from "../../hooks/keeper/usePublicAddress";
import { useNFT } from "../../hooks/useNFT";
import { useTokenOwner } from "../../hooks/useTokenOwner";
import { IPFSMetadata } from "../../IPFS/IPFSTypes";
import { BlockchainAssetDetails } from "../blockchainNetworks";
import { useBlockchainNetworks } from "../blockchainNetworks/blockchainNetworksProvider";

interface ShowNFTContextProps {
  asset: BlockchainAssetDetails | null;
  metadata: IPFSMetadata | null;
  owner: string | null;
  isCurrentUserOwner: boolean;
  error: string | null;
  loading: boolean;
}

export const ShowNFTContext = React.createContext<ShowNFTContextProps>(
  null as any
);

export const ShowNFTProvider: React.FC<{ assetId: string }> = ({
  assetId,
  children,
}) => {
  const { name: networkName } = useBlockchainNetworks();
  const [loading, setLoading] = React.useState(true);
  const publicAddress = usePublicAddress();
  const owner = useTokenOwner(assetId);

  const { asset, metadata, error, find } = useNFT(assetId as string);

  const isCurrentUserOwner = React.useMemo(() => {
    return publicAddress === owner;
  }, [publicAddress, owner]);

  React.useEffect(() => {
    if (!networkName) return;
    find();
    //eslint-disable-next-line
  }, [networkName, assetId]);

  // Force default loading state to true
  React.useEffect(() => {
    setLoading(!(!!(asset?.assetId && metadata?.timestamp) || !!error));
  }, [asset, metadata, error]);

  return (
    <ShowNFTContext.Provider
      value={{ asset, metadata, owner, error, loading, isCurrentUserOwner }}
    >
      {children}
    </ShowNFTContext.Provider>
  );
};

export const useShowNFT = () => React.useContext(ShowNFTContext);
