import React from "react";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import Captcha from "./Captcha";
import { Popup, PopupProps } from "./Popup";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { IPFSCreateMetadata } from "../IPFS/IPFSTypes";
import { useCreateNFT } from "../hooks/useCreateNFT";
import { useNavigate } from "react-router-dom";
import { useIPFS } from "../IPFS/useIPFS";
import { useNFTCollection } from "../providers/collections/NFTCollectionProvider";

export interface CreateNFTPopupProps extends PopupProps {
  metadata: IPFSCreateMetadata;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const CreateNFTPopup: React.FC<CreateNFTPopupProps> = (props) => {
  const [captcha, setCaptcha] = React.useState<string | null>(null);
  const flow = useCreateNFT();
  const ipfsService = useIPFS();
  const navigate = useNavigate();
  const { nftCreated } = useNFTCollection().userNfts;

  const handleClose = () => {
    if (flow.status === "failed") flow.reset();
    setCaptcha(null);
    props.onClose();
  };

  React.useEffect(() => {
    if (captcha) flow.start(captcha, props.metadata);

    // eslint-disable-next-line
  }, [captcha]);

  React.useEffect(() => {
    if (flow.status !== "success") return;

    const handle = setTimeout(() => {
      onCreated(flow.asset!);
    }, 1000);

    return () => clearTimeout(handle);
    // eslint-disable-next-line
  }, [flow.status]);

  const onCreated = (asset: BlockchainAssetDetails) => {
    ipfsService
      .getMetadata(flow.cid!)
      .then((metadata) => {
        nftCreated(asset.assetId, { metadata, error: false });
      })
      .catch((e) => {
        console.error("Failed do fetch metadata of created token: ", e);
      })
      .finally(() => {
        navigate(`/nft/${asset.assetId}`);
      });
  };

  const headerText = React.useMemo(() => {
    return flow.status === "failed"
      ? "NFT creation failed"
      : "Please wait. This can take a few seconds.";
  }, [flow.status]);

  return (
    <Popup visible={props.visible} onClose={handleClose} header={headerText}>
      {!captcha && (
        <Captcha
          setToken={(token) => setTimeout(() => setCaptcha(token), 500)}
        />
      )}
      {!!captcha && (
        <Container>
          {flow.status === "success" && (
            <img
              src="/images/svg/success-green.svg"
              alt="success"
              style={{ marginBottom: 50 }}
            />
          )}
          {flow.status === "failed" && (
            <img
              src="/images/svg/error-red.svg"
              alt="error"
              style={{ marginBottom: 50 }}
            />
          )}
          {flow.status === "working" && (
            <CircularProgress
              style={{ color: "var(--success)", marginBottom: 50 }}
              thickness={1}
              size={100}
            />
          )}
          <div style={{ fontSize: 16, fontWeight: 400 }}>
            {flow.status === "success" && "Transaction successful"}
            {flow.status === "failed" && (flow.error ?? "Unknown error")}
            {flow.status === "working" && "Transaction in progress..."}
          </div>
        </Container>
      )}
    </Popup>
  );
};
