import React from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

import usePublicAddress from "../hooks/keeper/usePublicAddress";
import { useNFTCollection } from "../providers/collections/NFTCollectionProvider";
import { useShowNFT } from "../providers/nft/ShowNFTContext";
import BurnButton from "./BurnButton";
import { BurnNFTPopup } from "./BurnNFTPopup";
import NFTActionButton from "./NFTActionButton";
import SendNFTPopup from "./SendNFTPopup";

const NFTActions = () => {
  const navigate = useNavigate();
  const publicAddress = usePublicAddress();
  const { asset, metadata, owner } = useShowNFT();
  const { removeNFTFromWallet } = useNFTCollection().userNfts;

  const [showBurnModal, setShowBurnModal] = React.useState<boolean>(false);
  const [showSendModal, setShowSendModal] = React.useState<boolean>(false);

  const handleCloseBurnModal = () => {
    setShowBurnModal(false);
  };

  const handleNFTRemoved = () => {
    if (asset) removeNFTFromWallet(asset.assetId);
    navigate("/", { replace: true });
  };

  const handleCloseSendModal = () => {
    setShowSendModal(false);
  };

  // Clear forms when Keeper account changes
  React.useEffect(() => {
    handleCloseSendModal();
    handleCloseBurnModal();
  }, [publicAddress]);

  if (!asset) {
    return (
      <div id="nft-actions">
        <Skeleton variant="rectangular" width="480px" height="36px" />
      </div>
    );
  }

  return (
    <div id="nft-actions">
      <NFTActionButton imgName={"export"} action={() => {}} disabled={true}>
        {"Export Qr"}
      </NFTActionButton>
      <NFTActionButton
        imgName={"send"}
        action={() => {
          setShowSendModal(true);
        }}
        disabled={owner !== publicAddress}
      >
        {"Send NFT"}
      </NFTActionButton>
      <NFTActionButton imgName={"buy-nft"} action={() => {}} disabled={true}>
        {"Buy NFT"}
      </NFTActionButton>
      <BurnButton
        disabled={owner !== publicAddress}
        action={() => {
          setShowBurnModal(true);
        }}
      />
      {asset && (
        <BurnNFTPopup
          visible={showBurnModal}
          onClose={handleCloseBurnModal}
          onSuccess={handleNFTRemoved}
          asset={asset}
        />
      )}
      {asset && (
        <SendNFTPopup
          visible={showSendModal}
          onClose={handleCloseSendModal}
          onSuccess={handleNFTRemoved}
          asset={asset}
          metadata={metadata}
        />
      )}
    </div>
  );
};

export default NFTActions;
