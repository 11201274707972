import React from "react";
import { BlockchainNetworkProvider } from "../blockchainNetworks/blockchainNetworksProvider";
import { KeeperServiceProvider } from "./KeeperServiceProvider";

type KeeperStatus =
  | "initializing"
  | "unavailable"
  | "notConnected"
  | "loaded"
  | "noAccount"
  | "connected"
  | "connecting";

const getKeeper = () => window.SkeyKeeper;

const waitForKeeper = (timeout: number) => {
  const interval = 100;
  const start = Date.now();

  return new Promise<boolean>((resolve) => {
    const handle = setInterval(async () => {
      if (!getKeeper()) {
        clearInterval(handle);
        return resolve(false);
      }

      if (getKeeper().initialPromise) {
        await getKeeper().initialPromise;

        clearInterval(handle);
        return resolve(true);
      }

      const timeDiff = Date.now() - start;

      if (timeDiff >= timeout) {
        clearInterval(handle);
        return resolve(false);
      }
    }, interval);
  });
};

export interface KeeperState {
  status: KeeperStatus;
  publicState: WavesKeeper.IPublicStateResponse | null;
  connect: () => Promise<void>;
}

export const KeeperContext = React.createContext<KeeperState>(null as any);

export const KeeperProvider: React.FC<{ connectOnLoad: boolean }> = (props) => {
  const [status, setStatus] = React.useState<KeeperStatus>("initializing");
  const [publicState, setPublicState] =
    React.useState<WavesKeeper.IPublicStateResponse | null>(null);

  React.useEffect(() => {
    setStatus("connecting");

    waitForKeeper(2000)
      .then((result) => {
        setStatus(result ? "loaded" : "unavailable");
        if (result && props.connectOnLoad) connect();
      })
      .catch((e) => {
        setStatus("unavailable");
      });

    // eslint-disable-next-line
  }, []);

  const connect = async () => {
    await SkeyKeeper?.publicState()
      .then((result) => {
        setStatus("connected");

        handleUpdate(result);
        getKeeper().on("update", handleUpdate);
      })
      .catch((e) => {
        switch (e.code) {
          case "14":
            // Keeper is connected, but it has no accounts set up
            setStatus("noAccount");
            getKeeper().on("update", handleUpdate);
            break;
          default:
            setStatus("notConnected");
        }
      });
  };

  const handleUpdate = (state: WavesKeeper.IPublicStateResponse) => {
    if (!state.account) {
      setStatus("noAccount");
    } else if (status !== "connected") {
      setStatus("connected");
    }
    setPublicState(state);
  };

  const state: KeeperState = {
    status,
    publicState,
    connect,
  };

  return (
    <KeeperContext.Provider value={state}>
      <BlockchainNetworkProvider>
        <KeeperServiceProvider>{props.children}</KeeperServiceProvider>
      </BlockchainNetworkProvider>
    </KeeperContext.Provider>
  );
};

export const useKeeper = () => React.useContext(KeeperContext);
