import { Skeleton } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useImageLoader } from "../hooks/useImage";
import type { UseFileUploaderState } from "../IPFS/useFileUploader";
import Card from "./Card";
import ImageUploaderProgress from "./ImageUploadProgress";

export interface NFTCardProps {
  name: string;
  description: string;
  url: string;
  uploader?: UseFileUploaderState;
  imageUrl?: string;
  dropzoneError?: boolean;
  setDropzoneError?: Function;
  loading?: boolean;
}

const Container = styled.div<NFTCardProps>`
  width: 100%;
  color: var(--dark);
  font-weight: 400;
  padding-bottom: 50px;
  max-width: 500px;
  word-wrap: break-word;

  @media (max-width: 1600px) {
    padding-bottom: 4px;
    /* min-width: 500px; */
  }

  .dropzone {
    width: 100%;
    max-width: 500px;
    height: 430px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: ${(p) => (p.imageUrl ? "default" : "pointer")};
    margin-bottom: 50px;

    @media (max-width: 450px) {
      min-width: 230px;
      min-height: 300px;
      height: unset;
      margin-bottom: 25px;
    }

    :hover {
      filter: ${(p) =>
        p.uploader?.status === "ready" ? "brightness(0.95)" : undefined};
    }

    &.error {
      border: 1px solid #f03535;
      background-color: rgba(240, 53, 53, 0.2);
      box-sizing: border-box;
    }
  }

  .dropzone-wrapper {
    height: 100%;
    width: 100%;
    position: relative;

    @media (max-width: 450px) {
      min-width: 230px;
      min-height: 300px;
    }
  }

  .dropzone-text {
    font-size: 24px;
    font-weight: 400;
    color: #b6b6b6;
    position: absolute;
    left: 50%;
    bottom: 25%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    &.error {
      color: #f03535;
    }
  }

  .name {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 36px;
    color: ${(p) => (p.name ? "var(--dark)" : "var(--gray)")};
    width: 100%;
    word-break: break-all;
    cursor: default;

    @media (max-width: 450px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
  }

  .desc {
    font-size: 12px;
    margin-bottom: 25px;
    line-height: 1.6;
    letter-spacing: 0.2px;
    color: ${(p) => (p.name ? "var(--dark)" : "var(--gray)")};
    cursor: default;
    width: 100%;

    @media (max-width: 450px) {
      margin-bottom: 20px;
      letter-spacing: 0.12px;
      font-size: 12px;
    }
  }

  .url {
    font-size: 12px;
    color: var(--link);
    cursor: ${(p) => (p.url ? "pointer" : "default")};
    width: 100%;
    word-break: break-all;
  }
`;

const getDropzoneStyling = (dropzoneError: boolean) => {
  return dropzoneError ? "error" : "";
};

const NFTCard: React.FC<NFTCardProps> = (props) => {
  const image = useImageLoader(props.imageUrl);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (!props.uploader) return;

    const MAX_SIZE_BYTES = 5 * 1024 * 1024;

    if (!acceptedFiles?.length) return;

    const file = acceptedFiles[0];

    if (file.size > MAX_SIZE_BYTES) {
      return alert("File is too big. Max size is 5 MB.");
    }

    if (file.size) props.uploader.upload(file);

    props.setDropzoneError!(false);
    // eslint-disable-next-line
  }, []);

  const dropzone = useDropzone({ onDrop, accept: "image/*" });

  const backgroundUrl = React.useMemo(() => {
    if (!props?.uploader?.file) return "images/svg/image-placeholder.svg";

    return URL.createObjectURL(props.uploader.file);
  }, [props?.uploader?.file]);

  const onUrlClick = () => {
    if (!props.url) return;

    const hasProtocol = /^(http|https):/.test(props.url);
    const url = hasProtocol ? props.url : `http://${props.url}`;

    window.open(url, "_blank")?.focus();
  };

  const dropzoneImage = (
    <div
      className={`dropzone ${getDropzoneStyling(props.dropzoneError!)}`}
      {...dropzone.getRootProps()}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <ImageUploaderProgress uploader={props.uploader} />
      <div className="dropzone-wrapper">
        <input {...dropzone.getInputProps()} />
        {props.uploader?.cid ? (
          <></>
        ) : (
          <p
            className={`dropzone-text ${getDropzoneStyling(
              props.dropzoneError!
            )}`}
          >
            Click to add photo
          </p>
        )}
      </div>
    </div>
  );

  const overrideImage = (
    <div
      className={`dropzone ${getDropzoneStyling(props.dropzoneError!)}`}
      style={{ backgroundImage: `url(${image.dataUrl})` }}
    />
  );

  const imagePlaceholder = (
    <div
      className={`dropzone ${getDropzoneStyling(props.dropzoneError!)}`}
      style={{ backgroundImage: `url("/images/image-placeholder.png")` }}
    />
  );

  const imageSkeleton = (
    <Skeleton
      variant="rectangular"
      width="100%"
      height="430px"
      sx={{ marginBottom: "50px" }}
      animation="pulse"
    />
  );

  const getImageElement = () => {
    if (props.uploader) return dropzoneImage;

    if (props.loading) return imageSkeleton;

    if (!props.imageUrl) return imagePlaceholder;

    if (image.status === "loading" || image.status === "ready")
      return imageSkeleton;

    return overrideImage;
  };

  return (
    <Card style={{ padding: 30 }} className="add-photo">
      <Container {...props}>
        {getImageElement()}
        <br />
        <div className="name">{props.name || "Title"}</div>
        <div className="desc">{props.description || "Description"}</div>
        <div className="url" onClick={onUrlClick}>
          {props.url || "URL"}
        </div>
      </Container>
    </Card>
  );
};

export default NFTCard;
