import React from "react";

interface SocialMediaComponentProps {
  link: string;
  name: string;
  imgName: string;
}

const SocialMediaComponent = ({
  link,
  name,
  imgName,
}: SocialMediaComponentProps) => {
  return (
    <a href={link} id="social-media-link">
      <img src={`/images/svg/${imgName}.svg`} alt="social-media-logo" />
      <span>{name}</span>
    </a>
  );
};

export default SocialMediaComponent;
