import styled from "styled-components";
import { Skeleton } from "@mui/material";

export interface INFTListItem {
  id: string;
  img?: string;
  name: string;
  description: string;
}

export interface NFTListItemProps extends INFTListItem {
  onClick: () => void;
  selected?: boolean;
  disableArrow?: boolean;
}

export const defaultProps: NFTListItemProps = Object.freeze({
  id: "",
  img: "",
  name: "Unknown",
  description: "-",
  onClick: () => null,
});

const Container = styled.div<{ selected?: boolean }>`
  height: 94px;
  overflow-y: hidden;
  background-color: ${(props) => (props.selected ? "#f6f6f6" : "white")};
  color: var(--dark);
  transition: linear 0.1s background-color;
  min-width: 250px;
  max-width: 600px;
  width: calc(100% - 50px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 25px;

  :hover {
    background-color: #f6f6f6;

    .arrow {
      color: var(--dark);
    }
  }

  img {
    width: 64px;
    height: 64px;
    padding-right: 15px;
  }

  .text-block {
    flex: 1;
  }

  .arrow {
    font-size: 26px;
    transform: scaleY(1.4);
    color: ${(props) => (props.selected ? "var(--dark)" : "#ddd")};
    padding-left: 15px;
    transition: linear 0.1s color;
  }

  .header {
    color: var(--dark);
    font-size: 12px;
    padding-bottom: 6px;
    font-weight: 600;
    line-height: 16px;
  }

  .desc {
    color: var(--dark);
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }
`;

const ThumbnailSkeleton = styled(Skeleton)`
  width: 64px;
  height: 64px;
  margin-right: 15px;
`;

const NameSkeleton = styled(Skeleton)`
  width: 160px;
  height: 16px;
`;

const DescriptionSkeleton = styled(Skeleton)`
  width: 140px;
  height: 14px;
`;

const truncateText = (text: string, maxLength: number) => {
  if (!text) return "";

  if (text.length <= maxLength) return text;

  return `${text.substring(0, maxLength)}...`;
};

const renderImage = (p: Partial<NFTListItemProps>) => {
  if (p.img) {
    return <img src={p.img} alt={p.name} />;
  } else if (!p.img && p.name) {
    return <img src="/images/image-placeholder.png" alt={p.name} />;
  } else {
    return <ThumbnailSkeleton variant="rectangular" />;
  }
};

const NFTListItem: React.FC<Partial<NFTListItemProps>> = (props) => {
  const p = { ...props };

  return (
    <Container onClick={p.onClick} selected={p.selected}>
      {renderImage(p)}
      <div className="text-block">
        <div className="header">
          {p.name ? truncateText(p.name, 30) : <NameSkeleton variant="text" />}
        </div>
        <div className="desc">
          {p.description ? (
            truncateText(p.description, 80)
          ) : (
            <DescriptionSkeleton variant="text" />
          )}
        </div>
      </div>
      {!p.disableArrow && <div className="arrow">{">"}</div>}
    </Container>
  );
};

export default NFTListItem;
