import styled, { css } from "styled-components";

export interface ButtonProps {
  variant: "contained" | "outlined" | "transparent";
  color: "primary" | "dark" | "gray" | "success" | "link" | "error";
  disabled: boolean;
  onClick: () => void;
  showArrowLeft: boolean;
  showArrowRight: boolean;
  shadow: boolean;
  style: React.CSSProperties;
  className?: string;
}

export const defaultProps: ButtonProps = Object.freeze({
  variant: "contained",
  color: "primary",
  disabled: false,
  onClick: () => null,
  showArrowLeft: false,
  showArrowRight: false,
  shadow: false,
  style: {},
  className: "",
});

const StyledButton = styled.button<ButtonProps>`
  max-height: 59px;
  min-width: 215px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${(p) =>
    p.variant === "outlined" ? `var(--${p.color})` : "var(--dark)"};
  border: ${(p) =>
    p.variant === "transparent" ? "none" : `1px solid var(--${p.color})`};
  background-color: ${(p) =>
    p.variant === "contained" ? `var(--${p.color})` : "transparent"};
  outline: none;
  padding: 12px 40px;
  cursor: ${(p) => (p.disabled ? "auto" : "pointer")};

  ${(p) =>
    p.disabled &&
    css`
      background-color: ${p.color === "primary" ? "var(--gray)" : "white"};
      border-color: var(--gray);
      color: ${p.color === "primary" ? "white" : "var(--gray)"};
    `}

  ${(p) =>
    p.shadow &&
    css`
      filter: drop-shadow(0px 58px 80px rgba(0, 0, 0, 0.05))
        drop-shadow(0px 37.5926px 46.8519px rgba(0, 0, 0, 0.037963))
        drop-shadow(0px 22.3407px 25.4815px rgba(0, 0, 0, 0.0303704))
        drop-shadow(0px 11.6px 13px rgba(0, 0, 0, 0.025))
        drop-shadow(0px 4.72593px 6.51852px rgba(0, 0, 0, 0.0196296))
        drop-shadow(0px 1.07407px 3.14815px rgba(0, 0, 0, 0.012037));
    `}
`;

const Button: React.FC<Partial<ButtonProps>> = (props) => {
  const p = { ...defaultProps, ...props };

  return (
    <StyledButton {...p} onClick={p.onClick}>
      {p.showArrowLeft && (
        <span style={{ marginRight: "0.6em", fontSize: "1em" }}>{"<"}</span>
      )}
      {p.children}
      {p.showArrowRight && (
        <span style={{ marginLeft: "0.6em", fontSize: "1em" }}>{">"}</span>
      )}
    </StyledButton>
  );
};

export default Button;
