import { Skeleton } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

interface QRProps {
  tokenAddress?: string;
  tokenName?: string;
  nftAddress?: string;
}

const QRWithTokenName = ({ tokenAddress, tokenName, nftAddress }: QRProps) => {
  return (
    <div id="qr-with-token-name">
      <div className="qr">
        {tokenAddress ? (
          <QRCode value={tokenAddress} size={96} />
        ) : (
          <Skeleton variant="rectangular" width={96} height={96} />
        )}
      </div>
      <span className="token-name">
        {tokenName ?? <Skeleton variant="text" height="72px" width="400px" />}
      </span>
      <span className="nft-address">
        {nftAddress && (
          <>
            <div>NFT ADDRESS:</div>
            <div>{nftAddress}</div>
          </>
        )}
      </span>
    </div>
  );
};

export default QRWithTokenName;
