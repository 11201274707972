export const poll = async <T>(
  fn: () => Promise<T>,
  rate: number,
  attemptsLimit: number
): Promise<T> => {
  let attempts = 0;
  let timeoutId: NodeJS.Timeout;

  return new Promise((resolve, reject) => {
    const nextAttempt = async () => {
      fn()
        .then((res) => {
          if (timeoutId) clearTimeout(timeoutId);
          resolve(res);
        })
        .catch((e) => {
          if (timeoutId) clearTimeout(timeoutId);

          if (attempts > attemptsLimit) {
            reject(e);
          } else {
            attempts += 1;
            timeoutId = setTimeout(nextAttempt, rate);
          }
        });
    };

    nextAttempt();
  });
};

export const pollWithTimeout = async <T>(
  fn: () => Promise<T>,
  rate: number,
  timeout: number
) => {
  const timestamp = new Date().valueOf();
  let timeoutId: NodeJS.Timeout;

  return new Promise((resolve, reject) => {
    const nextAttempt = async () => {
      fn()
        .then((res) => {
          if (timeoutId) clearTimeout(timeoutId);
          resolve(res);
        })
        .catch((e) => {
          if (timeoutId) clearTimeout(timeoutId);

          if (new Date().valueOf() - timestamp > timeout * 1000) {
            reject(e);
          } else {
            timeoutId = setTimeout(nextAttempt, rate);
          }
        });
    };

    nextAttempt();
  });
};
