import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  box-sizing: border-box;
  padding-top: 30px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 25px;
    margin-left: 28px;
  }
`;

const NFTListHeader: React.FC = () => {
  return (
    <Container>
      <div className="title">Your NFTs</div>
    </Container>
  );
};

export default NFTListHeader;
