import React from "react";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import Input from "./Input";
import * as Crypto from "@waves/ts-lib-crypto";
import Button from "./Button";
import { useBlockchainNetworks } from "../providers/blockchainNetworks/blockchainNetworksProvider";

interface InputProps {
  value: string;
  touched: boolean;
  error: string;
}

interface SendNFTFormProps {
  asset: BlockchainAssetDetails;
  onSubmit: (recipient: string) => Promise<any>;
}

const SendNFTForm: React.FC<SendNFTFormProps> = ({ asset, onSubmit }) => {
  const { network } = useBlockchainNetworks();
  const [recipient, setRecipient] = React.useState<InputProps>({
    value: "",
    touched: false,
    error: "",
  });
  const [validated, setValidated] = React.useState<boolean>(false);

  const setRecipientError = React.useCallback((e: any) => {
    setRecipient((prev) => ({ ...prev, error: e }));
  }, []);

  const handleRecipientChange = (val: string) => {
    setRecipient((prev) => ({ ...prev, value: val, touched: true }));
  };

  React.useEffect(() => {
    if (!recipient.touched) return;

    if (recipient.value.length === 0) {
      setRecipientError("Recipient cannot be empty");
    } else if (recipient.value.length !== 35) {
      setRecipientError("Not a blockchain address");
    } else if (
      !Crypto.verifyAddress(recipient.value, { chainId: network?.code })
    ) {
      setRecipientError("Invalid blockchain network");
    } else {
      setRecipientError("");
    }
  }, [recipient.value, recipient.touched, setRecipientError, network?.code]);

  React.useEffect(() => {
    setValidated(recipient.touched && recipient.error === "");
  }, [recipient.error, recipient.touched]);

  const handleSubmit = async () => {
    if (!validated) return;

    onSubmit(recipient.value).catch((e) => {
      console.error("Failed to send token: ", e);
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Input
        type="input"
        value={recipient.value}
        label={"Recipient"}
        error={recipient.error}
        setError={() => {}}
        maxLetters={35}
        placeholder={"Recipient blockchain address"}
        setInputValue={handleRecipientChange}
        className="transfer-token-input"
      />
      <Button onClick={handleSubmit} disabled={!validated}>
        Send
      </Button>
    </form>
  );
};

export default SendNFTForm;
