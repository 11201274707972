import React from "react";
import { usePageViewAnalytics } from "../../utils/analytics";
import Footer from "../Footer";
import Header from "../Header";
import "./layout.css";
import Sidebar from "./Sidebar";

const DesktopLayout: React.FC = (props) => {
  usePageViewAnalytics();

  return (
    <div id="main-layout">
      <div className="top-side">
        <aside className="left-side">
          <Sidebar />
        </aside>
        <div className="right-side">
          <div className="header-wrapper">
            <Header />
          </div>
          <div className="content-wrapper">{props.children}</div>
        </div>
      </div>
      <div className="bottom-side">
        <Footer />
      </div>
    </div>
  );
};

export default DesktopLayout;
