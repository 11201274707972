import React from "react";
import KeeperService from "./KeeperService";
import { useBlockchainNetworks } from "../blockchainNetworks/blockchainNetworksProvider";
import { useKeeper } from "./keeperProvider";

const KeeperServiceContext = React.createContext<KeeperService | undefined>(
  null as any
);

export const KeeperServiceProvider: React.FC = ({ children }) => {
  const { network } = useBlockchainNetworks();
  const { publicState } = useKeeper();

  const keeperService = React.useMemo(() => {
    if (!network || !publicState) return undefined;
    return new KeeperService(window.SkeyKeeper, network, publicState);
  }, [network, publicState]);

  return (
    <KeeperServiceContext.Provider value={keeperService}>
      {children}
    </KeeperServiceContext.Provider>
  );
};

export const useKeeperService = () => React.useContext(KeeperServiceContext);
