import React from "react";
import { Steps } from "./Progress";

interface ProgressTextProps {
  step?: Steps;
}

const ProgressText = ({ step }: ProgressTextProps) => {
  const getText = (step: Steps | undefined) => {
    if (step === 1) {
      return "Connect Your Skey Keeper Wallet";
    } else if (step === 2) {
      return "Add photo & details";
    } else if (step === 3) {
      return "Mission Complete!";
    } else {
      return "";
    }
  };
  return <h2 id="progress-step">{getText(step)}</h2>;
};

export default ProgressText;
