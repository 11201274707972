import React from "react";
import styled from "styled-components";
import { useIPFS } from "../IPFS/useIPFS";
import { useShowNFT } from "../providers/nft/ShowNFTContext";
import NFTNotFound from "../views/NFTNotFound";
import NFTCard from "./NFTCard";
import NFTData from "./NFTData";

interface NFTViewContainerProps {
  isCurrentUserOwner: boolean;
}

const NFTViewContainer = styled.div<NFTViewContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(p) => (p.isCurrentUserOwner ? "end" : "center")};

  @media (max-width: 1600px) {
    flex-direction: column;
    background-color: white;
    width: 100%;
    max-width: 560px;
  }
`;

const NFTView = () => {
  const { metadata, isCurrentUserOwner, error, loading } = useShowNFT();
  const ipfsService = useIPFS();

  if (error) return <NFTNotFound />;

  const mediaUrl = metadata?.mainMediaCid || metadata?.media?.[0]?.cid;
  const img = mediaUrl ? ipfsService.getUrl(mediaUrl) : undefined;

  console.log(metadata, loading);

  return (
    <NFTViewContainer isCurrentUserOwner={isCurrentUserOwner}>
      <NFTCard
        name={metadata?.name ?? ""}
        description={metadata?.description ?? ""}
        url={metadata?.attributes["urls"]?.[0] || ""}
        imageUrl={img}
        loading={loading}
      />
      <NFTData />
    </NFTViewContainer>
  );
};

export default NFTView;
