import { InputTypes } from "../components/Input";

export const URL_REGEX = new RegExp(
  "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const trimInput = (input: string): string => {
  const trimmed = input.slice(0, -1);
  return trimmed;
};

export const validateUrl = (text: string) => {
  if (text === "") return "";

  return URL_REGEX.test(text) ? "" : "URL is invalid";
};

export const validateInput = (
  input: string,
  maxLetters: number,
  type: InputTypes
): { output: string; error: string } => {
  if (type === "URL") {
    return { output: input, error: validateUrl(input) };
  }

  let output = input;
  let error = "";

  if (type !== "input" && type !== "textArea" && type !== "URL")
    throw new Error("Wrong input type");

  if (input.trim() === "") {
    error = "Field cannot be empty";
  }
  if (input.length > maxLetters) {
    output = trimInput(input);
  }

  return { output, error };
};
