import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import NFTListItem, { INFTListItem } from "./NFTListItem";
import React from "react";

export interface NFTListProps {
  items: Partial<INFTListItem>[];
  loading: boolean;
  style?: React.CSSProperties;
}

const Container = styled.div`
  overflow-y: scroll;
  padding-top: 10px;
  padding-bottom: 10px;
  scrollbar-color: #dadada #f6f6f6;
  scrollbar-width: 6px;

  ::-webkit-scrollbar {
    background-color: #f6f6f6;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dadada;
  }

  .empty {
    font-size: 12px;
    color: var(--gray);
    margin-top: 15px;
    margin-left: 28px;
  }
`;

const NFTList: React.FC<NFTListProps> = (props) => {
  const { assetId } = useParams();
  const navigate = useNavigate();

  const onItemClick = (assetId?: string) => {
    return () => {
      if (!assetId) return;
      navigate(`/nft/${assetId}`);
    };
  };

  const isSelected = (address?: string) => {
    return !!assetId && assetId === address;
  };

  if (props.loading) {
    const loadingList = new Array(5).fill(<NFTListItem disableArrow />);
    return (
      <Container style={props.style}>
        {loadingList.map((Element) => {
          return <React.Fragment key={Math.random()}>{Element}</React.Fragment>;
        })}
      </Container>
    );
  }

  return (
    <Container style={props.style}>
      {!props.items.length && <div className="empty">No NFTs yet.</div>}
      {props.items.map((item) => (
        <NFTListItem
          key={item.id}
          {...item}
          onClick={onItemClick(item.id)}
          selected={isSelected(item.id)}
        />
      ))}
    </Container>
  );
};

export default NFTList;
