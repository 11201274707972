import React from "react";
import Button from "./Button";
import HorizontalSpace from "./HorizontalSpace";
import { NFTCollection } from "../providers/collections";
import { isMobile } from "react-device-detect";

interface TokenSwitcherProps {
  items: NFTCollection;
  currentNFT: string;
  onPrev: (assetId: string) => any;
  onNext: (assetId: string) => any;
  isCurrentUserOwner: boolean;
}

const TokenSwitcher = ({
  items,
  currentNFT,
  onPrev,
  onNext,
  isCurrentUserOwner,
}: TokenSwitcherProps) => {
  const nftIds = React.useMemo(() => Object.keys(items), [items]);
  const currentNftIndex = React.useMemo(
    () => nftIds.findIndex((val) => val === currentNFT),
    [currentNFT, nftIds]
  );

  const hasNext = (): boolean => {
    return currentNftIndex < nftIds.length - 1;
  };

  const hasPrev = (): boolean => {
    return currentNftIndex > 0;
  };

  if (!isCurrentUserOwner) {
    return <></>;
  }

  if (isMobile) {
    return <></>;
  }

  return (
    <>
      <div id="token-switcher">
        <Button
          disabled={!hasPrev()}
          onClick={() => onPrev(nftIds[currentNftIndex - 1])}
          showArrowLeft
          variant="outlined"
          color="dark"
          className="prev"
        >
          Prev
        </Button>
        <HorizontalSpace space={"19px"} />
        <Button
          disabled={!hasNext()}
          onClick={() => onNext(nftIds[currentNftIndex + 1])}
          showArrowRight
          variant="outlined"
          color="dark"
          className="next"
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default TokenSwitcher;
