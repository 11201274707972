import { Skeleton } from "@mui/material";
import React from "react";

interface NFTAddressProps {
  nftAddress?: string;
}

const NFTAddress = ({ nftAddress }: NFTAddressProps) => {
  return (
    <div id="nft-address">
      <span className="address">NFT ADDRESS:</span>
      <span className="address">{nftAddress ?? <Skeleton width={350} />}</span>
    </div>
  );
};

export default NFTAddress;
