import React from "react";

export interface CardProps {
  style?: React.CSSProperties;
  className?: string;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div id="card" style={props.style} className={props.className}>
      {props.children}
    </div>
  );
};

export default Card;
