import React from "react";

import { BlockchainAssetDetails } from "../../providers/blockchainNetworks";
import { useBlockchainNetworks } from "../../providers/blockchainNetworks/blockchainNetworksProvider";
import { useKeeperService } from "../../providers/keeper/KeeperServiceProvider";

const MANAGER_ADDRESSES: { [key: string]: string | undefined } = {
  testnet: process.env.REACT_APP_NFT_MANAGER_DAPP_ADDRESS_TESTNET,
  mainnet: process.env.REACT_APP_NFT_MANAGER_DAPP_ADDRESS_MAINNET,
  custom: process.env.REACT_APP_NFT_MANAGER_DAPP_ADDRESS_CUSTOM,
};

const useNftBlockchainActions = () => {
  const keeperService = useKeeperService();
  const { network, name: networkName, apiService } = useBlockchainNetworks();

  const managerDappAddress = React.useMemo(() => {
    if (!networkName) return null;
    return MANAGER_ADDRESSES[networkName];
  }, [networkName]);

  const publishNft = React.useCallback(
    async (description: string): Promise<BlockchainAssetDetails> => {
      if (!keeperService || !network || !apiService) {
        throw new Error("Keeper not available");
      } else if (!managerDappAddress) {
        throw new Error(`Manager account not set for network ${networkName}`);
      }

      return await keeperService
        .invokeScript(
          managerDappAddress,
          "createNFT",
          [{ type: "string", value: description }],
          { payment: [{ assetId: "", amount: 10000000 }] }
        )
        .then(async (publishedTx) => {
          // InvokeScript transaction does not return info about created NFT
          // Fetch created asset ID manually
          return await apiService
            .transactionInfo(publishedTx.id)
            .then((txData) => {
              return txData.stateChanges.issues[0].assetId;
            });
        })
        .then(async (nftId: string) => {
          // Fetch asset info
          return await apiService.assetDetails(nftId);
        });
    },
    [keeperService, managerDappAddress, network, networkName, apiService]
  );

  const burnNFT = React.useCallback(
    async (
      assetId: string
    ): Promise<{ assetId: string; burned: boolean; error?: any }> => {
      if (!keeperService || !network || !apiService) {
        throw new Error("Keeper not available");
      } else if (!managerDappAddress) {
        throw new Error(`Manager account not set for network ${networkName}`);
      }

      return await keeperService
        .invokeScript(managerDappAddress, "burnNFT", [], {
          payment: [{ assetId, amount: 1 }],
        })
        .then(() => ({ assetId, burned: true }))
        .catch((e) => ({ assetId, burned: false, error: e }));
    },
    [keeperService, managerDappAddress, network, networkName, apiService]
  );

  const fetchNfts = React.useCallback(async () => {
    if (!apiService) {
      throw new Error("Network not available");
    } else if (!managerDappAddress) {
      throw new Error(`Manager account not set for network ${networkName}`);
    }

    const nftIds = await apiService
      .dataEntries(managerDappAddress, "^nft_[1-9A-HJ-NP-Za-km-z]{32,44}$")
      .then((entries) => {
        return entries
          .filter((entry) => entry.type === "boolean")
          .map((entry) => entry.key.replace("nft_", ""));
      });

    if (nftIds.length === 0) return [];

    return await apiService.assetsDetails(nftIds);
  }, [apiService, managerDappAddress, networkName]);

  const fetchAddressNfts = React.useCallback(
    async (
      address: string,
      after?: string
    ): Promise<BlockchainAssetDetails[]> => {
      if (!apiService) {
        throw new Error("Network not available");
      } else if (!managerDappAddress) {
        throw new Error(`Manager account not set for network ${networkName}`);
      }

      return await apiService.ownedNfts(address, after);
    },
    [apiService, managerDappAddress, networkName]
  );

  const fetchNft = React.useCallback(
    async (id: string) => {
      if (!apiService) throw new Error("Network not available");

      return await apiService.assetDetails(id);
    },
    [apiService]
  );

  const nftExists = React.useCallback(
    async (id: string) => {
      if (!apiService) {
        throw new Error("Network not available");
      } else if (!managerDappAddress) {
        throw new Error(`Manager account not set for network ${networkName}`);
      }

      const [entry] = await apiService.dataEntries(
        managerDappAddress,
        `nft_${id}`
      );

      return entry?.type === "string" && !!entry.value;
    },
    [apiService, managerDappAddress, networkName]
  );

  const fetchNFTOwner = React.useCallback(
    async (assetId: string): Promise<string | null> => {
      if (!apiService) {
        throw new Error("Network not available");
      }

      const distribution = await apiService.assetDistribution(assetId);

      const keys = Object.keys(distribution);

      // I was thinking about throwing an error when asset is not an NFT, but screw it
      // Let's just return the first address
      return keys.length > 0 ? keys[0] : null;
    },
    //eslint-disable-next-line
    [apiService, networkName]
  );

  const sendNFT = React.useCallback(
    async (
      assetId: string,
      recipient: string
    ): Promise<{ transferred: boolean; txData?: any; error?: any }> => {
      if (!keeperService) {
        throw new Error("Keeper not available");
      }

      return await keeperService
        .transfer(recipient, 1, assetId)
        .then((transferTx) => ({ transferred: true, txData: transferTx }))
        .catch((e) => ({ transferred: false, error: e }));
    },
    [keeperService]
  );

  return {
    publishNft,
    burnNFT,
    fetchNfts,
    fetchAddressNfts,
    fetchNft,
    nftExists,
    fetchNFTOwner,
    sendNFT,
  };
};

export default useNftBlockchainActions;
