import React from "react";
import SocialMediaComponent from "./SocialMediaComponent";

const Footer = () => {
  return (
    <div id="footer">
      <div className="social-media-wrapper">
        <SocialMediaComponent
          link={"https://t.me/go2nft_news"}
          name={"telegram"}
          imgName={"telegram"}
        />
        <SocialMediaComponent
          link={"https://twitter.com/go2nft_official"}
          name={"twitter"}
          imgName={"twitter"}
        />
        <SocialMediaComponent
          link={"https://www.instagram.com/go2nft/"}
          name={"instagram"}
          imgName={"instagram"}
        />
        <SocialMediaComponent
          link={"https://www.facebook.com/Go2NFT"}
          name={"facebook"}
          imgName={"facebook"}
        />
        <SocialMediaComponent
          link={"https://www.youtube.com/channel/UCcyUw8o0JQZmNL8M1GK3iuA"}
          name={"youtube"}
          imgName={"youtube"}
        />
      </div>
      <img
        src="/images/svg/footer-logo.svg"
        className="footer-logo"
        alt="logo"
      />
    </div>
  );
};

export default Footer;
