import React from "react";
import { inspect } from "util";
import { UploadStatus } from "./IPFSTypes";
import { useIPFS } from "./useIPFS";

export type Filelike = File | FileList;

export interface UseFileUploaderState {
  file: File | null;
  status: UploadStatus;
  cid: string | null;
  error: string | null;
  upload: (file: Filelike) => void;
  reset: () => void;
}

export const useFileUploader = (): UseFileUploaderState => {
  const ipfs = useIPFS();

  const [file, setFile] = React.useState<File | null>(null);
  const [status, setStatus] = React.useState<UploadStatus>("ready");
  const [cid, setCid] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const upload = async (input: Filelike) => {
    const file = input instanceof File ? input : input[0];

    setStatus("uploading");
    setFile(file);
    setCid(null);
    setError(null);

    try {
      const cid = await ipfs.uploadFile(file);
      setStatus("success");
      setCid(cid);
    } catch (e) {
      console.error(e);
      setStatus("failed");
      setError(inspect(e));
    }
  };

  const reset = () => {
    setFile(null);
    setStatus("ready");
    setCid(null);
    setError(null);
  };

  return {
    file,
    status,
    cid,
    error,
    upload,
    reset,
  };
};
