import { Skeleton } from "@mui/material";
import moment from "moment";
import React from "react";

interface CreatedAtProps {
  timestamp?: number;
}

const CreatedAt = ({ timestamp }: CreatedAtProps) => {
  const getDate = (timestamp: number) => {
    moment.locale(window.navigator.language ?? "en");
    return moment(timestamp).format("L");
  };

  if (!timestamp) {
    return <Skeleton width={150} height={10} sx={{ marginBottom: "18.5px" }} />;
  }

  return <span id="created-at">Creation date: {getDate(timestamp)}</span>;
};

export default CreatedAt;
