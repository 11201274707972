import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import NFTView from "../components/NFTView";
import SearchBar from "../components/SearchBar";
import { ShowNFTProvider } from "../providers/nft/ShowNFTContext";
import NFTNotFound from "./NFTNotFound";

const ShowNFT = () => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>("");
  const { assetId } = useParams();

  if (!assetId) {
    return <NFTNotFound />;
  }

  return (
    <ShowNFTProvider assetId={assetId}>
      <SearchBar
        value={search}
        setValue={setSearch}
        onSearch={() => navigate(`/nft/${search}`)}
        style={{ marginTop: 30 }}
      />
      <NFTView />
    </ShowNFTProvider>
  );
};

export default ShowNFT;
