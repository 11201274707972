import React from "react";
import { inspect } from "util";
import { IPFSService } from "../IPFS/IPFSService";
import { IPFSCreateMetadata } from "../IPFS/IPFSTypes";
import { useIPFS } from "../IPFS/useIPFS";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import { useKeeper } from "../providers/keeper/keeperProvider";
import useNftBlockchainActions from "./keeper/useNftBlockchainActions";

export type BlockchainActions = ReturnType<typeof useNftBlockchainActions>;

export type Status = "ready" | "working" | "success" | "failed";

export interface UseCreateNFTState {
  status: Status | null;
  error: string | null;
  start: (captcha: string, metadata: IPFSCreateMetadata) => void;
  cid: string | null;
  asset: BlockchainAssetDetails | null;
  reset: () => void;
}

export const getFaucetUrl = (chainId: string) => {
  const key = `REACT_APP_FAUCET_CHAIN_${chainId}_URL`;
  return process.env[key] ?? null;
};

export const requestFaucet = async (
  baseUrl: string | null,
  address: string,
  token: string
) => {
  if (!baseUrl) throw new Error("Faucet url is missing");

  const body = JSON.stringify({ address, token });

  const res = await fetch(baseUrl, {
    method: "POST",
    body,
    headers: { "Content-Type": "application/json" },
  });

  if (res.ok) return;

  console.error(res);

  throw new Error("Failed to use faucet");
};

export const createNFT = async (
  cid: string,
  blockchainActions: BlockchainActions
) => {
  try {
    return await blockchainActions.publishNft(cid);
  } catch (e: any) {
    console.error(e);

    if (e.code === "10") {
      throw new Error("Transaction rejected");
    } else {
      throw new Error("Transaction failed");
    }
  }
};

export const createMetadata = async (
  dto: IPFSCreateMetadata,
  ipfs: IPFSService
) => {
  try {
    return await ipfs.uploadMetadata(dto);
  } catch (e) {
    console.error(e);
    throw new Error("Failed to upload data to IPFS");
  }
};

export const useCreateNFT = (): UseCreateNFTState => {
  const keeper = useKeeper();
  const ipfs = useIPFS();
  const blockchainActions = useNftBlockchainActions();
  const [status, setStatus] = React.useState<Status>("ready");
  const [error, setError] = React.useState<string | null>(null);
  const [cid, setCid] = React.useState<string | null>(null);
  const [asset, setAsset] = React.useState<BlockchainAssetDetails | null>(null);

  const start = async (captcha: string, metadata: IPFSCreateMetadata) => {
    console.log("[useCreateNFT] start");
    setStatus("working");

    try {
      const { address, faucetUrl } = validateState();

      const [cid] = await Promise.all([
        ipfs.uploadMetadata(metadata),
        requestFaucet(faucetUrl, address, captcha),
      ]);

      setCid(cid);

      const asset = await createNFT(cid, blockchainActions);
      setAsset(asset);

      setStatus("success");
    } catch (e) {
      setStatus("failed");
      setError(inspect(e));
    }
  };

  const validateState = () => {
    const chainId = keeper.publicState?.network?.code;
    const address = keeper.publicState?.account?.address;
    const faucetUrl = getFaucetUrl(chainId ?? "");

    if (!faucetUrl || !address || !chainId) {
      throw new Error("Invalid app state");
    }

    return { chainId, address, faucetUrl };
  };

  const reset = () => {
    setStatus("ready");
    setError(null);
  };

  return { status, error, start, cid, asset, reset };
};
