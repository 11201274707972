import React from "react";
import useBurnNFTTransaction from "../hooks/keeper/useBurnNFTTransaction";
import { BlockchainAssetDetails } from "../providers/blockchainNetworks";
import { Popup, PopupProps } from "./Popup";
import Button from "./Button";
import styled from "styled-components";
import Spinner from "./Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 22px;
  justify-content: stretch;
  width: 100%;
`;

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 42px;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
`;

export interface BurnNFTPopupProps extends PopupProps {
  onSuccess: () => void;
  asset: BlockchainAssetDetails;
}

export const BurnNFTPopup: React.FC<BurnNFTPopupProps> = (props) => {
  const [popupHeader, setPopupHeader] = React.useState<string>("");
  const { status, perform, reset, error } = useBurnNFTTransaction();

  React.useEffect(() => {
    switch (status) {
      case "waitingForConfirm":
        setPopupHeader("Are you sure?");
        break;
      case "waitingForKeeperConfirm":
        setPopupHeader("Burning in progress");
        break;
      case "error":
        setPopupHeader("Failed to burn NFT");
        break;
      default:
        setPopupHeader("Burn NFT");
        break;
    }
  }, [status]);

  const onConfirm = () => {
    perform(props.asset.assetId);
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const onSuccess = () => {
    props.onSuccess();
    props.onClose();
  };

  return (
    <Popup header={popupHeader} visible={props.visible} onClose={onClose}>
      <Container>
        {status === "waitingForConfirm" && (
          <>
            <CenteredContainer>
              <Icon
                src="/images/svg/danger-triangle.svg"
                alt="Danger triangle"
              />
            </CenteredContainer>
            <Button
              variant="outlined"
              color="error"
              onClick={onConfirm}
              className="burn"
            >
              Burn My NFT
            </Button>
            <Button
              variant="outlined"
              color="dark"
              onClick={onClose}
              className="burn"
            >
              Close
            </Button>
          </>
        )}
        {status === "waitingForKeeperConfirm" && (
          <>
            <CenteredContainer>
              <Spinner />
              <span style={{ textAlign: "center" }}>
                Confirm the transaction in Keeper
              </span>
            </CenteredContainer>
          </>
        )}
        {status === "success" && (
          <>
            <CenteredContainer>
              <Icon src="/images/svg/success-green.svg" alt="Confirmed" />
              <h3 style={{ color: "var(--success)" }}>
                NFT burned successfully!
              </h3>
            </CenteredContainer>
            <Button variant="outlined" color="dark" onClick={onSuccess}>
              Close
            </Button>
          </>
        )}
        {status === "error" && (
          <>
            <CenteredContainer>
              <Icon
                src="/images/svg/danger-triangle.svg"
                alt="Danger triangle"
              />
              <h3 style={{ color: "var(--error)" }}>Failed to burn:</h3>
              <span style={{ color: "var(--error)" }}>{error}</span>
            </CenteredContainer>
            <Button variant="outlined" color="dark" onClick={onClose}>
              Close
            </Button>
          </>
        )}
      </Container>
    </Popup>
  );
};
