import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 547px;
  padding-top: 50px;
  row-gap: 20px;
  color: var(--error);
`;

const NFTNotFound: React.FC = () => {
  return (
    <Container>
      <img src="/images/svg/danger-triangle.svg" alt="Alert" />
      <h2>NFT not found</h2>
    </Container>
  );
};

export default NFTNotFound;
