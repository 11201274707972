import React from "react";
import { Popup, PopupProps } from "./Popup";
import styled from "styled-components";
import Button from "./Button";

export interface ConfirmNoImagePopupProps extends PopupProps {
  onContinue: () => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    line-height: 1.5;
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
  }

  .buttons {
    display: flex;
  }
`;

const ConfirmNoImagePopup: React.FC<ConfirmNoImagePopupProps> = (props) => {
  return (
    <Popup visible={props.visible} onClose={props.onClose} header="Warning">
      <Container>
        <div className="text">
          Are you sure you want to create an NFT without any image? The image
          cannot be added later.
        </div>
        <div className="buttons">
          <Button
            variant="outlined"
            color="dark"
            style={{ marginRight: 20 }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button onClick={props.onContinue}>Continue</Button>
        </div>
      </Container>
    </Popup>
  );
};

export default ConfirmNoImagePopup;
