import React from "react";

import { BlockchainNetwork, BlockchainNetworksCollection } from ".";
import { useKeeper } from "../keeper/keeperProvider";
import BlockchainApiService from "./BlockchainApiService";

const CONFIG_URL =
  "https://raw.githubusercontent.com/skey-network/skey-client-config/master/skey-keeper-config.json";

const FALLBACK_NETWORK =
  process.env.REACT_APP_FALLBACK_NETWORK_NAME ?? "testnet";

interface BlockchainNetworkProviderProps {
  name?: string;
  network?: BlockchainNetwork;
  apiService?: BlockchainApiService;
  validNetwork: boolean;
}

const BlockchainNetworkContext =
  React.createContext<BlockchainNetworkProviderProps>(null as any);

export const BlockchainNetworkProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { publicState } = useKeeper();

  const [networks, setNetworks] = React.useState<BlockchainNetworksCollection>(
    {}
  );
  const [network, setNetwork] = React.useState<BlockchainNetwork>();
  const [name, setName] = React.useState<string>();
  const [validNetwork, setValidNetwork] = React.useState<boolean>(false);

  const apiService = React.useMemo(() => {
    if (!network) return undefined;

    return new BlockchainApiService(network.server);
  }, [network]);

  const getNetworkNameByChainId = React.useCallback(
    (chainId: string): string | undefined => {
      return Object.entries(networks).find(
        ([_, network]) => network.code === chainId
      )?.[0];
    },
    [networks]
  );

  const getNetworkByChainId = React.useCallback(
    (chainId: string): BlockchainNetwork | undefined => {
      return Object.values(networks).find(
        (network) => network.code === chainId
      );
    },
    [networks]
  );

  React.useEffect(() => {
    setLoading(true);
    fetch(CONFIG_URL)
      .then((res) => res.json())
      .then((config: { network_config: BlockchainNetworksCollection }) => {
        delete config.network_config.custom;
        setNetworks(config.network_config);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (loading) return;
    const keeperNetwork = publicState?.network;

    if (!keeperNetwork) {
      const fallback = networks[FALLBACK_NETWORK];

      if (fallback) {
        setNetwork(fallback);
        setName(FALLBACK_NETWORK);
      } else {
        setNetwork(undefined);
        setName(undefined);
      }

      return;
    }

    const chainId = keeperNetwork.code;

    const savedNetworkName = getNetworkNameByChainId(chainId);

    if (savedNetworkName) {
      setNetwork(getNetworkByChainId(chainId));
      setName(savedNetworkName);
    } else {
      setNetwork({ ...keeperNetwork });
      setName("custom");
    }
  }, [
    publicState?.network,
    networks,
    getNetworkNameByChainId,
    getNetworkByChainId,
    loading,
  ]);

  React.useEffect(() => {
    if (loading) return;

    const keeperNetwork = publicState?.network;

    if (!keeperNetwork) {
      setValidNetwork(false);
      return;
    }

    const savedNetworkName = getNetworkNameByChainId(keeperNetwork.code);

    setValidNetwork(savedNetworkName === FALLBACK_NETWORK);
  }, [getNetworkNameByChainId, loading, publicState?.network]);

  return (
    <BlockchainNetworkContext.Provider
      value={{ network, name, apiService, validNetwork }}
    >
      {children}
    </BlockchainNetworkContext.Provider>
  );
};

export const useBlockchainNetworks = () =>
  React.useContext(BlockchainNetworkContext);
