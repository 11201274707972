import React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoToMain = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          fontSize: "calc(36px + 11vw)",
          textAlign: "center",
          marginTop: "20vh",
        }}
      >
        404
      </h1>
      <h4
        style={{
          fontSize: "calc(20px + 3vw)",
          textAlign: "center",
          marginBottom: "calc(20px + 2vw)",
        }}
      >
        Page Not Found
      </h4>
      <Button
        showArrowLeft
        variant="outlined"
        color="dark"
        onClick={handleGoToMain}
      >
        Go To Main
      </Button>
    </div>
  );
};

export default NotFound;
