import React from "react";

export type Steps = 1 | 2 | 3 | number;

interface ProgressProps {
  step?: Steps | undefined;
  width: string | number;
  style?: React.CSSProperties;
}

const Progress = ({ step, width, style }: ProgressProps) => {
  const setActive = (myPosition: number) => {
    return step === myPosition ? "active" : "default";
  };

  return (
    <div id="progress" style={{ width: width, ...style }}>
      {step ? (
        <>
          <span className="create-new-text">Create new NFT</span>
          <div className="numbers-bar">
            <span className={setActive(1)}>1</span>
            <span className={setActive(2)}>2</span>
            <span className={setActive(3)}>3</span>
          </div>
          <div className="progress-bar">
            {step === 1 && (
              <>
                <div className="dot dot-1 color" />
                <div className="line line-1"></div>
                <div className="dot dot-2" />
                <div className="line line-2 blank"></div>
                <div className="dot dot-3" />
              </>
            )}
            {step === 2 && (
              <>
                <div className="dot dot-1 color" />
                <div className="line line-1 color"></div>
                <div className="dot dot-2 color" />
                <div className="line line-2 blank"></div>
                <div className="dot dot-3" />
              </>
            )}
            {step === 3 && (
              <>
                <div className="dot dot-1 color" />
                <div className="line line-1 color"></div>
                <div className="dot dot-2 color" />
                <div className="line line-2 color"></div>
                <div className="dot dot-3 color" />
              </>
            )}
          </div>
          <div className="text-bar">
            <span className={setActive(1)}>Connect wallet</span>
            <span className={setActive(2)}>Add photo & details</span>
            <span className={setActive(3)}>Create NFT</span>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Progress;
