import { plainToInstance } from "class-transformer";
import { validate } from "class-validator";
import {
  IPFSCreateMetadata,
  IPFSConfig,
  IPFSMetadata,
  IPFSMetadataSchema,
  IPFSMedia,
} from "./IPFSTypes";
import { inspect } from "util";

export class IPFSService {
  private config: IPFSConfig;

  constructor(config?: IPFSConfig) {
    this.config = config ?? IPFSService.loadConfigFromEnv();
  }

  async getMetadata(cid: string): Promise<IPFSMetadata> {
    const obj = await this.getJSON(cid);
    const instance = plainToInstance(IPFSMetadataSchema, obj);
    const errors = await validate(instance);

    if (errors.length) throw new Error("Metadata validation failed");

    return obj;
  }

  getUrl(cid: string, filename?: string) {
    if (!cid) return "";

    const query = filename ? `?filename=${filename}` : "";
    return `${this.config.gatewayUrl}/ipfs/${cid}${query}`;
  }

  async getJSON(cid: string) {
    const res = await fetch(this.getUrl(cid));
    return await res.json();
  }

  async getFileBytes(cid: string) {
    const res = await fetch(this.getUrl(cid));
    return await res.arrayBuffer();
  }

  async uploadJSON(obj: Record<string, any>): Promise<string> {
    const res = await fetch(`${this.config.apiUrl}/pinning/pinJSONToIPFS`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.config.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });

    const data = await res.json();

    if (!res.ok) throw new Error(inspect(data));

    return data.IpfsHash;
  }

  async uploadFile(file: File): Promise<string> {
    const form = new FormData();

    form.append("file", file, file.name);

    const res = await fetch(`${this.config.apiUrl}/pinning/pinFileToIPFS`, {
      method: "POST",
      headers: { Authorization: `Bearer ${this.config.token}` },
      body: form,
    });

    const data = await res.json();

    if (!res.ok) throw new Error(inspect(data));

    return data.IpfsHash;
  }

  async uploadMetadata(dto: IPFSCreateMetadata) {
    if (!!dto.file !== !!dto.fileCid) throw new Error("invalid file data");

    const media: IPFSMedia[] =
      dto.file && dto.fileCid
        ? [
            {
              cid: dto.fileCid,
              filename: dto.file.name,
              type: dto.file.type,
            },
          ]
        : [];

    const metadata: IPFSMetadata = {
      name: dto.name ?? "",
      description: dto.description ?? "",
      attributes: dto.url ? { urls: [dto.url] } : {},
      creator: dto.creator ?? "",
      mainMediaCid: dto.fileCid ?? null,
      media,
      timestamp: Date.now(),
      schemaVersion: this.config.schemaVersion,
    };

    return await this.uploadJSON(metadata);
  }

  getConfig() {
    return this.config;
  }

  static loadConfigFromEnv(): IPFSConfig {
    const env = (key: string) => process.env[`REACT_APP_IPFS_${key}`]!;

    if (!env("TOKEN")) throw new Error("IPFS token is missing");

    return {
      apiUrl: env("API_URL") ?? "https://api.pinata.cloud",
      gatewayUrl: env("GATEWAY_URL") ?? "https://gateway.pinata.cloud",
      schemaVersion: Number(env("SCHEMA_VERSION") ?? "1"),
      token: env("TOKEN"),
    };
  }
}
