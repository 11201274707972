import React from "react";
import { KeeperProvider } from "./providers/keeper/keeperProvider";
import DesktopLayout from "./components/Layout/DesktopLayout";
import { NFTCollectionProvider } from "./providers/collections/NFTCollectionProvider";
import Router from "./routing/Router";
import { routes } from "./routing/routes";

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <KeeperProvider connectOnLoad>
        <NFTCollectionProvider>
          <Router routes={routes} Layout={DesktopLayout} />
        </NFTCollectionProvider>
      </KeeperProvider>
    </React.StrictMode>
  );
};

export default App;
