import React from "react";
import { useKeeper } from "../providers/keeper/keeperProvider";
import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
  background-color: white;
  width: calc(100% - 15px);
  height: 250px;
  padding-top: 50px;
  padding-left: 28px;
  box-sizing: border-box;

  .keeper-logo {
    width: 140px;
    height: 30px;
    margin-bottom: 10px;
  }

  .main {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 50px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ConnectWallet: React.FC = () => {
  const keeper = useKeeper();

  const keeperUrl =
    "https://chrome.google.com/webstore/detail/skey-keeper/dcigkldapeipokagfmcjaijmngcagkcp";

  return (
    <Container>
      <img
        className="keeper-logo"
        src="/images/svg/skey-keeper-logo.svg"
        alt="skey-keeper"
      />
      <div className="main">Connect Your Skey Keeper</div>
      <div
        className="desc"
        onClick={() => window.open(keeperUrl, "_blank")?.focus()}
      >
        Download for chrome
      </div>
      <Button
        color="dark"
        onClick={keeper.connect}
        variant="outlined"
        style={{ fontSize: 16, width: 173, height: 48, padding: 0 }}
      >
        Connect Wallet
      </Button>
    </Container>
  );
};

export default ConnectWallet;
