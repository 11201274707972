import React from "react";
import Card from "./Card";

const ConnectWalletCard = () => {
  return (
    <Card>
      <div className="wrapper">
        <img
          src="images/svg/skey-logo.svg"
          className="skey-logo"
          alt="Skey Logo"
        />
        <img src="images/svg/wires.svg" className="wires" alt="Wires" />
      </div>
    </Card>
  );
};

export default ConnectWalletCard;
