import React from "react";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return <span className="error-message">{message}</span>;
};

export default ErrorMessage;
