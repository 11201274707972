import React from "react";
import { validateInput } from "../utils/validator";
import ErrorMessage from "./ErrorMessage";
import LetterCounter from "./LetterCounter";

export type InputTypes = "input" | "textArea" | "URL";

interface InputProps {
  type: InputTypes;
  value: string;
  label: string;
  error: string;
  setError: Function;
  setInputValue: Function;
  maxLetters: number;
  style?: React.CSSProperties;
  placeholder: string;
  className?: string;
}

const Input = ({
  type,
  value,
  error,
  setError,
  label,
  setInputValue,
  maxLetters,
  style,
  placeholder,
  className,
}: InputProps) => {
  const [inputFocus, setInputFocus] = React.useState(false);

  const inputFocusHandler = (state: boolean) => {
    if (!error) {
      setInputFocus(state);
    }
  };

  const getInputStyling = (inputFocus: boolean, error: string) => {
    if (error) {
      return "error";
    }
    if (inputFocus) {
      return "focused";
    }
    return "default";
  };

  const handleWriteToInput = (
    input: string,
    maxLetters: number,
    type: InputTypes
  ) => {
    const { output, error } = validateInput(input, maxLetters, type);
    setInputValue(output);
    setError(error);
  };

  return (
    <div id="input" className={className}>
      <div className={`input-wrapper ` + getInputStyling(inputFocus, error)}>
        <label>{label}</label>
        {type === "input" && (
          <input
            type="text"
            maxLength={maxLetters}
            onFocus={() => inputFocusHandler(true)}
            onBlur={() => inputFocusHandler(false)}
            onChange={(e) =>
              handleWriteToInput(e.currentTarget.value, maxLetters, "input")
            }
            value={value}
            className={getInputStyling(inputFocus, error) + " title"}
            placeholder={placeholder}
            style={{ ...style }}
          />
        )}
        {type === "textArea" && (
          <textarea
            maxLength={maxLetters}
            onFocus={() => inputFocusHandler(true)}
            onBlur={() => inputFocusHandler(false)}
            onChange={(e) =>
              handleWriteToInput(e.currentTarget.value, maxLetters, "textArea")
            }
            value={value}
            className={getInputStyling(inputFocus, error) + " description"}
            placeholder={placeholder}
            style={{ ...style }}
          />
        )}
        {type === "URL" && (
          <input
            type="url"
            maxLength={maxLetters}
            onFocus={() => inputFocusHandler(true)}
            onBlur={() => inputFocusHandler(false)}
            onChange={(e) =>
              handleWriteToInput(e.currentTarget.value, maxLetters, "URL")
            }
            value={value}
            className={getInputStyling(inputFocus, error) + " url"}
            placeholder={placeholder}
            style={{ ...style }}
          />
        )}
      </div>
      <div className="under-input">
        <ErrorMessage message={error} />
        <LetterCounter current={value.length} max={maxLetters} />
      </div>
    </div>
  );
};

export default Input;
