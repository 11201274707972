import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NFTListItem from "./NFTListItem";
import SidebarSeparator from "./SidebarSeparator";

const SeparatorContainer = styled.div`
  padding-left: 25px;
`;

const CreateNFTListItem = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/create");
  };

  return (
    <>
      <NFTListItem
        disableArrow
        name="Create new NFT"
        description=" "
        img="/images/svg/sidebar-create-nft.svg"
        onClick={onClick}
      />
      <SeparatorContainer>
        <SidebarSeparator />
      </SeparatorContainer>
    </>
  );
};

export default CreateNFTListItem;
