import React from "react";

export type ImageLoaderStatus = "ready" | "loading" | "success" | "failed";

export const getImage = async (url: string) => {
  try {
    const res = await fetch(url);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useImageLoader = (url?: string | null) => {
  const [status, setStatus] = React.useState<ImageLoaderStatus>("ready");
  const [content, setContent] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!url) return;

    setStatus("loading");

    getImage(url).then((content) => {
      if (content) {
        setContent(content);
        setStatus("success");
      } else {
        setContent(null);
        setStatus("failed");
      }
    });
  }, [url]);

  return { status, dataUrl: content };
};
