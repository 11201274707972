import { BlockchainAssetDetails } from ".";

export default class BlockchainApiService {
  public serverUrl: string;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl + (serverUrl.endsWith("/") ? "" : "/");
  }

  public async transactionInfo(txId: string) {
    return await fetch(`${this.serverUrl}transactions/info/${txId}`).then(
      (res) => res.json()
    );
  }

  public async assetDetails(assetId: string): Promise<BlockchainAssetDetails> {
    return await fetch(`${this.serverUrl}assets/details/${assetId}`)
      .then((res) => {
        return res.json();
      })
      .then((asset) => asset as BlockchainAssetDetails);
  }

  public async assetsDetails(
    assetIds: string[]
  ): Promise<BlockchainAssetDetails[]> {
    const params = new URLSearchParams();

    assetIds.forEach((assetId) => {
      params.append("id", assetId);
    });

    return await fetch(`${this.serverUrl}assets/details?` + params)
      .then((res) => res.json())
      .then((assets: BlockchainAssetDetails[]) => assets);
  }

  public async dataEntries(
    address: string,
    matches?: string
  ): Promise<WavesKeeper.TData[]> {
    const params: Record<string, string> = {};

    if (matches) {
      params.matches = matches;
    }

    const url = this.buildUrl(
      `${this.serverUrl}addresses/data/${address}?`,
      params
    );
    return await fetch(url)
      .then(async (res) => {
        return await res.json();
      })
      .catch((e) => {
        return [];
      });
  }

  public async ownedNfts(address: string, after?: string) {
    const url = `${this.serverUrl}assets/nft/${address}/limit/1000?`;

    const query = new URLSearchParams({});

    if (after) {
      query.append("after", after);
    }

    return await fetch(url + query)
      .then((res) => res.json())
      .then((assets: BlockchainAssetDetails[]) =>
        assets.filter((asset) => asset.name === "Go2NFT" && asset.description)
      );
  }

  public async assetDistribution(
    assetId: string
  ): Promise<Record<string, number>> {
    // fetch current height
    const lastHeight = await this.fetchLastHeight();

    // Fetching asset distribution at current height will return an error,
    // so we fetch distribution at the block before
    const url = `${this.serverUrl}assets/${assetId}/distribution/${
      lastHeight - 1
    }/limit/100`;

    return await fetch(url)
      .then((res) => res.json())
      .then((dist) => dist.items);
  }

  public async fetchLastHeight(): Promise<number> {
    return await fetch(`${this.serverUrl}blocks/last`)
      .then((res) => res.json())
      .then((block) => block.height);
  }

  private buildUrl = (url: string, params: Record<string, string> = {}) => {
    return url + new URLSearchParams(params);
  };
}
