import React from "react";
import CreatedAt from "./CreatedAt";
import NFTActions from "./NFTActions";
import OwnerInfo from "./OwnerInfo";
import QRWithTokenName from "./QRWithTokenName";
import TokenSwitcher from "./TokenSwitcher";
import NFTAddress from "./NFTAddress";
import { useShowNFT } from "../providers/nft/ShowNFTContext";
import { useNFTCollection } from "../providers/collections/NFTCollectionProvider";
import { useNavigate } from "react-router";

const NFTData: React.FC<{}> = () => {
  const { collection } = useNFTCollection().userNfts;
  const { asset, metadata, owner, isCurrentUserOwner } = useShowNFT();
  const navigate = useNavigate();

  const changeNFT = (assetId: string) => {
    navigate(`/nft/${assetId}`, { replace: true });
  };

  return (
    <div id="nft-data">
      <QRWithTokenName
        tokenAddress={asset?.assetId}
        tokenName={metadata?.name}
        nftAddress={asset?.assetId}
      />
      <CreatedAt timestamp={metadata?.timestamp} />
      <NFTAddress nftAddress={asset?.assetId} />
      <NFTActions />
      <OwnerInfo address={owner} />
      <TokenSwitcher
        items={collection}
        currentNFT={asset?.assetId as string}
        onPrev={changeNFT}
        onNext={changeNFT}
        isCurrentUserOwner={isCurrentUserOwner}
      />
    </div>
  );
};

export default NFTData;
