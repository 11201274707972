import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export interface CaptchaProps {
  setToken: (token: string | null) => void;
}

const Captcha: React.FC<CaptchaProps> = (props) => {
  const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;
  if (!siteKey) throw new Error("recaptcha key missing");

  return <ReCAPTCHA sitekey={siteKey} onChange={props.setToken} />;
};

export default Captcha;
