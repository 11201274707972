import React from "react";

import { NFTCollection } from ".";
import useNftBlockchainActions from "../../hooks/keeper/useNftBlockchainActions";
import usePublicAddress from "../../hooks/keeper/usePublicAddress";
import { IPFSMetadataFetchResult } from "../../IPFS/IPFSTypes";
import { useIPFS } from "../../IPFS/useIPFS";
import { BlockchainAssetDetails } from "../blockchainNetworks";
import { useBlockchainNetworks } from "../blockchainNetworks/blockchainNetworksProvider";

interface NFTCollectionContextProps {
  userNfts: {
    loading: boolean;
    collection: NFTCollection;
    mightBeMore: boolean;
    loadingMore: boolean;
    fetchNextBatch: () => Promise<void>;
    nftCreated: (id: string, fetchResult: IPFSMetadataFetchResult) => void;
    removeNFTFromWallet: (id: string) => void;
  };
}

export const NFTCollectionContext =
  React.createContext<NFTCollectionContextProps>(null as any);

export const NFTCollectionProvider: React.FC = ({ children }) => {
  const [userNFTsLoading, setUserNFTsLoading] = React.useState<boolean>(false);
  const [userNFTs, setUserNFTs] = React.useState<NFTCollection>({});
  const [mightBeMoreNFTs, setMightBeMoreNFTs] = React.useState<boolean>(false);
  const [loadingMoreNFTs, setLoadingMoreNFTs] = React.useState<boolean>(false);
  const { name: networkName } = useBlockchainNetworks();

  const ipfsService = useIPFS();
  const { fetchAddressNfts } = useNftBlockchainActions();
  const publicAddress = usePublicAddress();

  const fetchNFTDataFromIPFS = React.useCallback(
    async (
      details: BlockchainAssetDetails
    ): Promise<[string, IPFSMetadataFetchResult]> => {
      const result: IPFSMetadataFetchResult = await ipfsService
        .getMetadata(details.description as string)
        .then((metadata) => ({ metadata, error: false }))
        .catch((e) => ({ error: true }));

      return [details.assetId, result];
    },
    [ipfsService]
  );

  const fetchDataFromIPFS = React.useCallback(
    async (details: BlockchainAssetDetails[]) => {
      return await Promise.all(
        details.map(async (blockchainNft) => {
          return await fetchNFTDataFromIPFS(blockchainNft);
        })
      );
    },
    [fetchNFTDataFromIPFS]
  );

  const fetchNfts = React.useCallback(
    async (
      address: string,
      after?: string
    ): Promise<[string, IPFSMetadataFetchResult][]> => {
      return await fetchAddressNfts(address, after).then(async (details) => {
        return await fetchDataFromIPFS(details);
      });
    },
    [fetchAddressNfts, fetchDataFromIPFS]
  );

  React.useEffect(() => {
    if (!ipfsService || !networkName) return;
    if (!publicAddress) {
      setUserNFTs({});
      setMightBeMoreNFTs(false);
      return;
    }

    setUserNFTsLoading(true);

    fetchNfts(publicAddress)
      .then((items) => {
        setUserNFTs(Object.fromEntries(items));
        setMightBeMoreNFTs(items.length > 0);
      })
      .catch((e) => {
        console.error("[NFTCollectionProvider] Failed to fetch user NFTs: ", e);
        setUserNFTs({});
      })
      .finally(() => {
        setUserNFTsLoading(false);
      });
    //eslint-disable-next-line
  }, [publicAddress, ipfsService, networkName]);

  const fetchNextBatch = React.useCallback(async () => {
    if (!publicAddress) return;

    const keys = Object.keys(userNFTs);

    if (keys.length === 0) {
      setMightBeMoreNFTs(false);
      return;
    }

    const lastAssetId = keys[keys.length - 1];

    setLoadingMoreNFTs(true);

    fetchNfts(publicAddress, lastAssetId)
      .then((items) => {
        setMightBeMoreNFTs(items.length > 0);
        if (items.length === 0) return;

        setUserNFTs((prev) => {
          return { ...prev, ...Object.fromEntries(items) };
        });
      })
      .catch((e) => {
        console.error(
          "[NFTCollectionProvider] Failed to fetch next batch of NFTs: ",
          e
        );
      })
      .finally(() => {
        setLoadingMoreNFTs(false);
      });

    //eslint-disable-next-line
  }, [userNFTs, publicAddress]);

  const nftCreated = (id: string, fetchResult: IPFSMetadataFetchResult) => {
    setUserNFTs((prev) => ({ ...prev, [id]: fetchResult }));
  };

  const removeNFTFromWallet = (id: string) => {
    setUserNFTs((prev) => {
      const copy = { ...prev };
      delete copy[id];
      return copy;
    });
  };

  return (
    <NFTCollectionContext.Provider
      value={{
        userNfts: {
          collection: userNFTs,
          mightBeMore: mightBeMoreNFTs,
          loadingMore: loadingMoreNFTs,
          fetchNextBatch,
          nftCreated,
          removeNFTFromWallet,
          loading: userNFTsLoading,
        },
      }}
    >
      {children}
    </NFTCollectionContext.Provider>
  );
};

export const useNFTCollection = () => React.useContext(NFTCollectionContext);
