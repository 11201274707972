import React from "react";

interface BurnButtonInterface {
  disabled: boolean;
  action: Function;
  style?: React.CSSProperties;
}

const BurnButton = ({ disabled, action, style }: BurnButtonInterface) => {
  return (
    <button
      id="burn-nft"
      disabled={disabled}
      onClick={() => action()}
      style={{ ...style }}
    >
      Burn NFT
    </button>
  );
};

export default BurnButton;
