import { pollWithTimeout } from "../../utils/polling";
import { BlockchainNetwork } from "../blockchainNetworks";

export default class KeeperService {
  public keeper: WavesKeeper.TWavesKeeperApi;
  public network: BlockchainNetwork;
  public publicState: WavesKeeper.IPublicStateResponse;

  constructor(
    keeper: WavesKeeper.TWavesKeeperApi,
    network: BlockchainNetwork,
    publicState: WavesKeeper.IPublicStateResponse
  ) {
    this.keeper = keeper;
    this.network = network;
    this.publicState = publicState;
  }

  public async publishTransaction(tx: WavesKeeper.TSignTransactionData) {
    return await this.keeper
      .signAndPublishTransaction(tx)
      .then(async (res) => {
        const txData = JSON.parse(res);

        return await this.waitForTx(txData.id);
      })
      .then((txInfo) => {
        console.log("[KeeperService] Published transaction: ", txInfo);
        return txInfo;
      })
      .catch((e) => {
        console.error("[KeeperService] Failed to publish transaction", e);
        throw e;
      });
  }

  public async invokeScript(
    dApp: string,
    functionName: string,
    args: WavesKeeper.TCallArgs[],
    custom?: Partial<WavesKeeper.IScriptInvocationTx>
  ) {
    const hasScript = await this.checkScript();
    const fee = hasScript ? 0.009 : 0.005;

    const invokeTxData: WavesKeeper.TScriptInvocationTxData = {
      type: 16,
      data: {
        dApp,
        call: {
          function: functionName,
          args,
        },
        payment: [],
        fee: {
          tokens: fee,
          assetId: "",
        },
        ...custom,
      },
    };

    return await this.publishTransaction(invokeTxData);
  }

  public async transfer(
    recipient: string,
    amount: number,
    assetId?: string,
    custom?: Partial<WavesKeeper.ITransferTx>
  ) {
    const hasScript = await this.checkScript();
    const fee = hasScript ? 0.005 : 0.001;

    const transferTxData: WavesKeeper.TTransferTxData = {
      type: 4,
      data: {
        recipient,
        amount: {
          tokens: amount,
          assetId: assetId ?? "",
        },
        fee: {
          tokens: fee,
          assetId: "",
        },
        ...custom,
      },
    };

    return await this.publishTransaction(transferTxData);
  }

  public async waitForTx(txId: string): Promise<any> {
    return await pollWithTimeout(async () => this.fetchTx(txId), 1000, 10);
  }

  // If cannot fetch script, assume address is smart account and charge higher fee
  private async checkScript(): Promise<boolean> {
    const address = this.publicState?.account?.address;

    if (!address) {
      console.error("Address missing. Cannot fetch script info.");
      return true;
    }

    try {
      const data = await this.request(`/addresses/scriptInfo/${address}`);
      return !!data?.script;
    } catch (e) {
      console.error(e);
      return true;
    }
  }

  private async fetchTx(txId: string): Promise<any> {
    return await this.request(`/transactions/info/${txId}`);
  }

  private async request<T = any>(path: string): Promise<T> {
    return await fetch(`${this.network.server}${path}`)
      .then((res) => {
        if (res.status === 200) return res.json();

        throw res.json();
      })
      .then((js) => js);
  }
}
