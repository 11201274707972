import { base58Encode, randomBytes } from "@waves/ts-lib-crypto";
import React from "react";
import * as IdentityImg from "identity-img";
import { shortAddress } from "./WalletInfo";
import { Skeleton } from "@mui/material";

interface OwnerInfoProps {
  address: string | null;
}

const OwnerInfo: React.FC<OwnerInfoProps> = ({ address }) => {
  const avatarUrl = React.useMemo(() => {
    const rand = base58Encode(randomBytes(35));
    const input = address ?? rand;

    return IdentityImg.create(input, { size: 120, rows: 8, cells: 8 });
  }, [address]);

  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = (address: string | null) => {
    navigator.clipboard.writeText(address ?? "");
    setIsCopied(true);
    const timeout = setTimeout(() => {
      setIsCopied(false);
      clearTimeout(timeout);
    }, 1000);
  };

  const buildURL = (address: string) => {
    return `https://explorer.smartkeyplatform.io/testnet/address/${address}/tx`;
  };

  return (
    <div id="owner-info-wrapper">
      <div className="owner-side">
        <span className="owner-text">Owner</span>
        <div className="owner-info">
          {address ? (
            <img src={avatarUrl} alt="Owner Avatar" className="avatar" />
          ) : (
            <Skeleton
              variant="circular"
              height={24}
              width={24}
              sx={{ marginRight: "13px" }}
            />
          )}
          <p className="owner-address">
            {address ? shortAddress(address) : <Skeleton width={120} />}
          </p>
          {isCopied ? (
            <p className="copied">Copied!</p>
          ) : (
            <button
              onClick={() => handleCopy(address)}
              disabled={!address}
              className="copy"
              style={{ opacity: address ? 1 : 0.4 }}
            >
              <img
                src="/images/svg/copy-btn.svg"
                alt="copy-btn"
                // style={{ cursor: address ? "pointer" : "auto" }}
              />
            </button>
          )}
        </div>
      </div>
      {/* {address && ( */}
      <div className="explorer-side">
        <a
          type="link"
          href={buildURL(address!)}
          target="_blank"
          rel="noreferrer"
          className={!address ? "disabled" : ""}
        >
          {"view on explorer >"}
        </a>
      </div>
      {/* )} */}
    </div>
  );
};

export default OwnerInfo;
